<template>

        <div class="layout">

            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center no-gutters min-vh-100">

                    <div class="col-12 col-md-5 col-lg-4 py-8 primary">
                        <div class="text-center mb-7 animated fadeInDown faster">
                            <img src="/assets/images/logo.png" width="150" class="d-block-inline">
                            <!--<div class="badge badge-dark" style="border-radius: 3px">BETA</div>-->

                            <!-- Preload image -->
                            <div class="d-none">
                                <img src="/assets/images/logo-pen.png" width="300" class="d-block-inline">
                            </div>
                        </div>

                        <slot/>

                    </div>
                </div> <!-- / .row -->
            </div>

        </div><!-- .layout -->
</template>





<script>
export default {
  components: {
  
  }
}
</script>
