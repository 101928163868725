<template>
    <div>
        <div class="progress">
            <div class="progress-bar" role="progressbar" :style="progressWidth" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="d-flex align-content-start align-items-center flex-wrap">
        <div v-for="n in starCount1000" class="star star-1000 animated rotateIn faster"></div>
        <div v-for="n in starCount100" class="star star-100 animated rotateIn faster"></div>
        </div>
    </div>
</template>
    

<script>
export default {
    name: 'writerProgressBar',

    props: [
        'user', 'wordCount'
    ],

    computed: 
    {
        starCount100: function () {            
            var wordCount = this.wordCount - (this.starCount1000 * 1000)
            var stars = Math.floor(wordCount / 100)
            return stars
        },

        starCount1000: function () {
            var stars = Math.floor(this.wordCount / 1000)
            return stars
        },

        progressWidth: function () {

            var amount = Math.floor(this.wordCount / 100);
            var percent = this.wordCount - (amount * 100);

            return {
                width: percent + '%'
            }
        }
    }
}
</script>

<style>
    .star {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        margin-top: 10px;
    }
    .dark .star-100 {
        background: transparent url('/assets/images/star-light.png') no-repeat center center;
        background-size: 10px;
    }
    .star-100 {
        background: transparent url('/assets/images/star-dark.png') no-repeat center center;
        background-size: 10px;
    }
    .star-1000 {
        width: 15px;
        height: 15px;
        background: transparent url('/assets/images/star.png') no-repeat center center;
        background-size: 15px;
    }

</style>