<template>
    <div class="animated fadeIn fast">
        <h1 class="font-bold text-center">Sign In</h1>
    
        <p class="text-center mb-6">Please sign in below.</p>

        <div class="alert alert-danger" role="alert" v-if="error">Something went wrong, please try again later</div>
        
        <!-- Form -->
        <form class="mb-6" @submit.prevent="submit">
            <!-- Email -->
            <div class="form-group">
                <label for="email" class="sr-only">Email Address</label>
                <input type="email" class="form-control form-control-lg" :class="[{'is-invalid': errorFor('email')}]" id="email" placeholder="Enter your email" v-model="form.email">
                <div class="invalid-feedback" v-for="(error, index) in errorFor('email')" :key="'email' + index">
                    {{ error }}
                </div>
            </div>

            <!-- Password -->
            <div class="form-group">
                <label for="password" class="sr-only">Password</label>
                <input type="password" class="form-control form-control-lg" :class="[{'is-invalid': errorFor('password')}]" id="password" placeholder="Enter your password" v-model="form.password">
                        <div class="invalid-feedback" v-for="(error, index) in errorFor('password')" :key="'password' + index">
                            {{ error }}
                        </div>
            </div>

            <div class="form-group d-flex justify-content-between">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" checked="" id="checkbox-remember">
                    <label class="custom-control-label" for="checkbox-remember">Remember me</label>
                </div>
                <router-link :to="{name: 'forgotPassword'}">Forgot password</router-link>
            </div>

            <!-- Submit -->
            <button class="btn btn-lg btn-block btn-primary" :class="[{'disabled': sending}]" type="submit" :disable="sending">Sign in <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div></button>
        </form>

        <!-- Text -->
        <p class="text-center">
            Don't have an account yet? <router-link :to="{name: 'signup'}">Sign up</router-link>. 
        </p>
        <div class="text-center">Can't login but need your words? <a href="" @click.prevent="copyContentWriting">Copy words</a>.</div>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import { isError422 } from "../utils/response";
import _ from 'lodash'
import Vue from 'vue'

export default {
    name: 'signin',

    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            error: false,
            errors: null,
            sending: false,
        }
    },

    components: {

    },

    methods: {
        copyContentWriting() 
        {
            var str = localStorage.getItem('writingHTML')
            let plainStr = localStorage.getItem('writingText')
            
            function listener(e) {
                e.clipboardData.setData("text/html", str);
                e.clipboardData.setData("text/plain", plainStr);
                e.preventDefault();
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
    
            Vue.$toast.success('Your writing has been copied to clipboard')
        },

        ...mapActions({
            signIn: 'auth/signIn',
        }),

        async submit() {
            this.sending = true;
            this.errors = null;
            this.error = false;

            this.signIn(this.form).then(() => {
                if (this.$route.params.redirect) {
                    this.$router.replace(this.$route.params.redirect);
                    return
                }
                this.$router.replace({
                    name: 'rooms',
                });
            })
            .catch((err) => {
                if (isError422(err)) {
                    const errors = err.response.data.errors;
                    this.errors = errors;
                    return;
                }
                this.error = true;
            })
            .then(() => {
                this.sending = false;
            });
        },

        errorFor(field) {
            return this.errors != null && this.errors[field] ? this.errors[field] : null;
        }
    }
}
</script>
