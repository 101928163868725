var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animated fadeIn fast"},[(_vm.signingIn)?_c('div',{staticClass:"text-center animated fadeIn fast"},[_c('div',{staticClass:"spinner-border text-dark spinner-border-lg mb-2 mt-5",attrs:{"role":"status"}}),_c('h1',{staticClass:"font-bold text-center mb-8"},[_vm._v("Confirm your email")]),_vm._m(0),_c('p',[_c('router-link',{attrs:{"to":{name: 'signin'}}},[_vm._v("Sign in")]),_vm._v(".")],1)]):_c('div',[_c('h1',{staticClass:"font-bold text-center"},[_vm._v("Sign Up")]),_c('p',{staticClass:"text-center mb-6"},[_vm._v("Please sign up below.")]),(_vm.error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v("Something went wrong, please try again later")]):_vm._e(),(!_vm.signingIn)?_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"mb-6",on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"name"}},[_vm._v("Full Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.name),expression:"form.name",modifiers:{"trim":true}}],staticClass:"form-control form-control-lg",class:[{'is-invalid': errors.length}],attrs:{"type":"name","id":"name","placeholder":"Enter your name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._l((errors),function(error,index){return _c('div',{key:'email' + index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control form-control-lg",class:[{'is-invalid': errors.length}],attrs:{"type":"email","id":"email","placeholder":"Enter your email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._l((errors),function(error,index){return _c('div',{key:'email' + index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"username","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],staticClass:"form-control form-control-lg",class:[{'is-invalid': errors.length}],attrs:{"type":"username","id":"username","placeholder":"Enter your username"},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "username", $event.target.value)}}}),_vm._l((errors),function(error,index){return _c('div',{key:'username' + index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","vid":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control form-control-lg",class:[{'is-invalid': errors.length}],attrs:{"type":"password","id":"password","placeholder":"Enter your password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_vm._l((errors),function(error,index){return _c('div',{key:'password' + index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"passwordConfirmation","vid":"passwordConfirmation","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"password_confirmation"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_confirmation),expression:"form.password_confirmation"}],staticClass:"form-control form-control-lg",class:[{'is-invalid': errors.length}],attrs:{"type":"password","id":"password_confirmation","placeholder":"Confirm your password"},domProps:{"value":(_vm.form.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_confirmation", $event.target.value)}}}),_vm._l((errors),function(error,index){return _c('div',{key:'password_confirmation' + index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('button',{staticClass:"btn btn-lg btn-block btn-primary",class:[{'disabled': _vm.sending}],attrs:{"type":"submit","disable":_vm.sending}},[_vm._v("Sign up "),(_vm.sending)?_c('div',{staticClass:"spinner-border text-light spinner-border-sm ml-2",attrs:{"role":"status"}}):_vm._e()])],1)]}}],null,false,3665257611)}):_vm._e(),_c('p',{staticClass:"text-center"},[_vm._v(" Already have an account? "),_c('router-link',{attrs:{"to":{name: 'signin'}}},[_vm._v("Sign in")]),_vm._v(". ")],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("To complete your registration,"),_c('br'),_vm._v(" check your email for validation request.")])}]

export { render, staticRenderFns }