<template>
    <div>
        <!-- Message -->
        <div class="message mb-5" :class="[{'message-right': isSender}]">
            <!-- Message: body -->
            <div class="message-body">

                <!-- Message: row -->
                <div class="message-row">
                    <div class="d-flex align-items-center" :class="[{'justify-content-end': isSender}]">

                        <!-- Message: dropdown -->
                        <div class="dropdown" v-if="isSender && message.id">
                            <a class="text-muted opacity-60 ml-3" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fe-more-vertical"></i>
                            </a>

                            <div class="dropdown-menu">
                                <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="deleteMessage(message)">
                                    Delete <span class="ml-auto fe-trash-2"></span>
                                </a>
                            </div>
                        </div>
                        <!-- Message: dropdown -->

                        <!-- Message: content -->
                        <div class="message-content" 
                            :class="[{'bg-primary text-white': isSender, 'bg-light': !isSender}]" 
                        >
                            <div v-html="messageContent"></div>

                            <div class="mt-1 small">
                                <span class="mb-0 small pr-3" style="font-weight: bold" :class="[{'text-white': isSender}]">{{ message.user.name }}</span>
                                <TimeAgo refresh v-if="!message.created_at" long tooltip></TimeAgo>
                                <TimeAgo refresh v-if="message.created_at" :datetime="message.created_at" long tooltip></TimeAgo>
                            </div>
                        </div>
                        <!-- Message: content -->
                    </div>
                </div>
                <!-- Message: row -->

            </div>
            <!-- Message: Body -->


        </div>
        <!-- Message -->
        <!-- Avatar 
        <a class="avatar avatar-sm mt-4 mb-4" :class="[{'float-right': isSender}]" href="#" data-chat-sidebar-toggle="#chat-1-user-profile">
            <img class="avatar-img" src="/assets/images/avatars/7.jpg" alt="">
        </a>-->
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import TimeAgo from 'vue2-timeago'
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import 'linkifyjs/lib/linkify-string'

Vue.use(VTooltip)

export default {
    name: 'chatRoomMessage',
    components: {
        TimeAgo
    },

    props: [
        'message',
    ],

    created() {
       
    },

    computed: {
        ...mapGetters({
            user: 'auth/user', 
        }),
        isSender() {
            return this.message.user.id == this.user.id
        },
        messageContent() {
            if (this.message.content.includes('class="giphy"')) {
                return this.message.content;
            }
            return this.message.content.linkify()
        }
    },

    methods: {
        deleteMessage(message) 
        {
            this.$emit('delete-message', message);
        }
    }
}
</script>

<style>
    .avatar-sm, .avatar-sm img {
        min-width: 2rem;
        min-height: 2rem;
        height: 2rem;
        width: 2rem;
    }
    .message-row .v-time-ago__text {
        opacity: .65!important;
        font-size: 86.667%!important;
        font-weight: 400;
        color: inherit!important;
        font-family: inherit!important;
    }
    .message-content.text-white a {
        color: #fff !important;
        text-decoration: underline;
    }
</style>