<template>
    <div class="animated fadeIn fast">
        <!-- Heading -->
        <h1 class="font-bold text-center">Reset Password</h1>

        <div v-if="success" class="animated fadeIn fast">

            <p class="text-center">
                Your password has been reset.
            </p>
  
            <p class="text-center">
                <router-link :to="{name: 'signin'}">Sign in</router-link>
            </p>
        </div>
        <div v-else>
            <!-- Text -->
            <p class="text-center mb-6">Enter your new password.</p>

            <!-- Form -->
            <ValidationObserver ref="form" v-slot="{ passes }">
                <form class="mb-6" @submit.prevent="passes(submit)">

                <input type="hidden" name="email" v-model="form.email">
                <input type="hidden" name="token" v-model="form.token">

                <!-- Password -->
                <ValidationProvider name="password" vid="password" rules="required|min:6" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="password" class="sr-only">Password</label>
                        <input type="password" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="password" placeholder="Enter your password" v-model="form.password">
                        <div class="invalid-feedback" v-for="(error, index) in errors" :key="'password' + index">
                            {{ error }}
                        </div>
                    </div>
                </ValidationProvider>

                <!-- Confirm Password -->
                <ValidationProvider name="passwordConfirmation" vid="passwordConfirmation" rules="required|confirmed:password" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="password_confirmation" class="sr-only">Confirm Password</label>
                        <input type="password" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="password_confirmation" placeholder="Confirm your password" v-model="form.password_confirmation">
                        <div class="invalid-feedback" v-for="(error, index) in errors" :key="'password_confirmation' + index">
                            {{ error }}
                        </div>
                    </div>
                </ValidationProvider>

                    <!-- Submit -->
                    <button class="btn btn-lg btn-block btn-primary" :class="[{'disabled': sending}]" type="submit" :disable="sending">Reset Password <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div></button>
                </form>
            </ValidationObserver>

           
        </div>
    </div>
</template>


<script>
import Vue from 'vue'
import { isError422 } from "../utils/response";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, confirmed, min } from 'vee-validate/dist/rules';
import axios from 'axios'
import _ from 'lodash'

extend('required', {
  ...required,
  message: 'Required'
});
extend('confirmed', confirmed);
extend('min', min);

export default {
    name: 'forgotPassword',

    components: {
        ValidationProvider,
        ValidationObserver
    },

    data() {
        return {
            form: {
                email: '',
                token: '',
                password: '',
                password_confirmation: '',
            },
            error: false,
            errors: null,
            sending: false,
            success: false,
        }
    },

    created() {
        this.form.email = this.$route.query.email
        this.form.token = this.$route.params.token
    },

    methods: {

        // /password/email
        async submit() {
            this.sending = true;
            this.error = false;

            await axios.post('/password/reset', this.form).then(() => 
            {
                this.success = true
            })
            .catch((err) => 
            {
                if (isError422(err)) {
                    const errors = err.response.data.errors;
                    this.$refs.form.setErrors(errors);
                    return;
                }
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later', {
                    duration: 6000,
                });
                this.error = true;
            })
            .then(() => {
                this.sending = false;
            });

        },

    }
}
</script>
