<template>
    <div>
        <ValidationObserver ref="form" v-slot="{ passes }" enctype="multipart/form-data">
        <form class="mb-6" @submit.prevent="passes(submit)">
            
            <ValidationProvider name="password" rules="required" v-slot="{ errors }">
            <div class="form-group">
                <label class="small" for="password">New password</label>
                <input name="new-password" id="new-password" type="password"
                    class="form-control form-control-lg" placeholder="New password"
                    :class="[{'is-invalid': errors.length}]" 
                    v-model="form.password"
                >
                <div class="invalid-feedback" v-for="(error, index) in errors" :key="'name' + index">
                    {{ error }}
                </div>
            </div>
            </ValidationProvider>

            <!-- Confirm Password -->
            <ValidationProvider name="passwordConfirmation" vid="passwordConfirmation" rules="required|confirmed:password" v-slot="{ errors }">
                <div class="form-group">
                    <label for="password_confirmation" class="small">Confirm Password</label>
                    <input type="password" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="password_confirmation" placeholder="Confirm your password" v-model="form.password_confirmation">
                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'password_confirmation' + index">
                        {{ error }}
                    </div>
                </div>
            </ValidationProvider>

            <button class="btn btn-lg btn-primary btn-block" :class="[{'disabled': sending}]" type="submit" :disable="sending">
                Change Password <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div>
            </button>
        </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Vue from 'vue'
import { isError422 } from "../../utils/response";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, confirmed, min } from 'vee-validate/dist/rules';
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Required'
});
extend('confirmed', confirmed);
extend('min', min);

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data () {
        return {
            form: {
                password: null,
                password_confirmation: null,
            },
            sending: false,
            error: false,
        };
    },

    methods: {
        
        // Submit profile form
        async submit() {
            this.sending = true
            this.error = false

            axios.post('/user/password', this.form)
            .then((response) =>
            {
                Vue.$toast.success('Password saved')
            })
            .catch((err) => 
            {
                if (isError422(err)) {
                    const errors = err.response.data.errors;
                    this.$refs.form.setErrors(errors);
                    return;
                }
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                this.error = true;
            })
            .then(() => {
                this.sending = false;
            });
            

        },
    }
}
</script>