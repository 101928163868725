<template>
    <div :class="classNames">
        <ins
        class="adsbygoogle"
        data-ad-format="fluid"
        :data-ad-layout-key="layoutKey"
        :style="{display: 'block'}"
        :data-ad-client="googleAdId"
        :data-ad-slot="slotId"
        ></ins>
    </div>
</template>


<script>
export default {
  name: "AdSenseInFeed",
  props: {
    timeout: Number,
    classNames: String,
    slotId: String,
    layoutKey: String,
  },

  watch: {
    slotId: function () {
      this.render()
    }
  },
  data() {
    return {
      googleInit: null,
      googleAdId: "ca-pub-8241810292521301"
    };
  },
  methods: {
    render() {
      let timeout = 200;
      if(this.timeout) timeout = this.timeout;
      this.googleInit = setTimeout(() => {
        if (typeof window !== "undefined")
          (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, timeout);
    }
  },
  mounted() {
    this.render()
  },
  destroyed() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }
};
</script>
