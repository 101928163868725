<template>
    <div>
        <ValidationObserver ref="form" v-slot="{ passes }">
        <form class="mb-6" @submit.prevent="passes(submit)">
            
            <ValidationProvider name="facebook" :rules="{ regex: urlRegex }" v-slot="{ errors }">
                <!-- Facebook -->
                <div class="form-group">
                    <label class="small" for="profile-facebook">Facebook</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="btn btn-ico btn-secondary btn-minimal">
                                <i class="fe-facebook"></i>
                            </div>
                        </div>
                        <input id="profile-facebook" type="text" class="form-control form-control-lg" placeholder="https://facebook.com/Username" aria-label="Username"
                            :class="[{'is-invalid': errors.length}]" 
                            v-model="form.facebook"
                        >
                    </div>
                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'facebook' + index">
                        {{ error }}
                    </div>
                </div>
            </ValidationProvider>

            <ValidationProvider name="instagram" :rules="{ regex: urlRegex }" v-slot="{ errors }">
                <!-- Instagram -->
                <div class="form-group">
                    <label class="small" for="profile-instagram">Instagram</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="btn btn-ico btn-secondary btn-minimal">
                                <i class="fe-instagram"></i>
                            </div>
                        </div>
                        <input id="profile-instagram" type="text" class="form-control form-control-lg" placeholder="https://instagram.com/Username" aria-label="Username"
                            :class="[{'is-invalid': errors.length}]" 
                            v-model="form.instagram"
                        >
                    </div>
                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'instagram' + index">
                        {{ error }}
                    </div>
                </div>
            </ValidationProvider>

            <ValidationProvider name="twitter" :rules="{ regex: urlRegex }" v-slot="{ errors }">
                <!-- Twitter -->
                <div class="form-group">
                    <label class="small" for="profile-twitter">Twitter</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="btn btn-ico btn-secondary btn-minimal">
                                <i class="fe-twitter"></i>
                            </div>
                        </div>
                        <input id="profile-twitter" type="text" class="form-control form-control-lg" placeholder="https://twitter.com/Username" aria-label="Username"
                            :class="[{'is-invalid': errors.length}]" 
                            v-model="form.twitter"
                        >
                    </div>
                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'twitter' + index">
                        {{ error }}
                    </div>
                </div>
            </ValidationProvider>

            <ValidationProvider name="website" :rules="{ regex: urlRegex }" v-slot="{ errors }">
                <!-- Website -->
                <div class="form-group">
                    <label class="small" for="profile-website">Website</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="btn btn-ico btn-secondary btn-minimal">
                                <i class="fe-monitor"></i>
                            </div>
                        </div>
                        <input id="profile-website" type="text" class="form-control form-control-lg" placeholder="https://website.com" aria-label="Username"
                            :class="[{'is-invalid': errors.length}]" 
                            v-model="form.website"
                        >
                    </div>
                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'website' + index">
                        {{ error }}
                    </div>
                </div>
            </ValidationProvider>

            <button class="btn btn-lg btn-primary btn-block" :class="[{'disabled': sending}]" type="submit" :disable="sending">
                Save <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div>
            </button>

        </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isError422 } from "../../utils/response";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';
import axios from 'axios'

extend('regex', regex);

export default {
    name: "SocialForm",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    computed: {
        ...mapGetters({
            user: 'auth/user', 
        }),
    },
    created() {
        if (typeof this.user.links !== undefined && this.user.links != null) {
            this.form = {
                facebook: this.user.links.facebook,
                twitter: this.user.links.twitter,
                website: this.user.links.website,
                instagram: this.user.links.instagram,
            }
        }
    },
    data () {
        return {
            form: {
                facebook: null,
                instagram: null,
                website: null,
                twitter: null,
            },
            sending: false,
            error: false,
            urlRegex: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
        };
    },

    methods: {
        
        // Submit profile form
        async submit() {
            this.sending = true
            this.error = false
            axios.get('/csrf-cookie').then(() => 
            {
                axios.post('/user/socials', this.form)
                .then((response) =>
                {
                    this.$store.commit('auth/SET_USER', response.data);
                    Vue.$toast.success('Socials saved')
                })
                .catch((err) => 
                {
                    if (isError422(err)) {
                        const errors = err.response.data.errors;
                        this.$refs.form.setErrors(errors);
                        return;
                    }
                    Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                    this.error = true;
                })
                .then(() => {
                    this.sending = false;
                });
            });
            

        },
    }
}
</script>