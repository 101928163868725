var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form",attrs:{"enctype":"multipart/form-data"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"mb-6",on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"small"},[_vm._v("Avatar")]),(_vm.avatar && !_vm.image)?_c('div',{staticClass:"avatar-photo"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.avatarImage}})]):_vm._e(),(_vm.image)?_c('Cropper',{ref:"cropper",attrs:{"classname":"upload-cropper","minHeight":20,"stencilProps":{
                        height: 100,
                        width: 100
                    },"src":_vm.image,"stencilComponent":_vm.$options.components.CircleStencil}}):_vm._e(),_c('div',{staticClass:"upload-placeholder position-relative text-center bg-secondary rounded p-6"},[_c('div',{staticClass:"avatar bg-primary text-white mb-5"},[_c('i',{staticClass:"icon-md fe-image"})]),_c('p',{staticClass:"small text-muted mb-0"},[_vm._v("You can upload jpg, gif or png files. "),_c('br'),_vm._v(" Max file size 3mb.")]),_c('input',{ref:"file",staticClass:"d-none",attrs:{"id":"upload-user-photo","type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadImage($event)}}}),_c('label',{staticClass:"stretched-label mb-0",attrs:{"for":"upload-user-photo"}})])],1),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"small",attrs:{"for":"profile-name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control form-control-lg",class:[{'is-invalid': errors.length}],attrs:{"name":"profile-name","id":"profile-name","type":"text","placeholder":"Type your name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_vm._l((errors),function(error,index){return _c('div',{key:'name' + index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"small",attrs:{"for":"profile-username"}},[_vm._v("Username (cannot be changed)")]),_c('input',{staticClass:"form-control form-control-lg",attrs:{"name":"profile-username","id":"profile-username","type":"text","disabled":"","readonly":""},domProps:{"value":_vm.user.username}})]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"small",attrs:{"for":"profile-email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control form-control-lg",class:[{'is-invalid': errors.length}],attrs:{"name":"profile-email","id":"profile-email","type":"email","placeholder":"you@yoursite.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._l((errors),function(error,index){return _c('div',{key:'name' + index,staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"small",attrs:{"for":"profile-about"}},[_vm._v("Bio")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.bio),expression:"form.bio"}],staticClass:"form-control form-control-lg",attrs:{"id":"profile-about","rows":"3","placeholder":"Express yourself","data-autosize":"true"},domProps:{"value":(_vm.form.bio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "bio", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-lg btn-primary btn-block",class:[{'disabled': _vm.sending}],attrs:{"type":"submit","disable":_vm.sending}},[_vm._v(" Save Profile "),(_vm.sending)?_c('div',{staticClass:"spinner-border text-light spinner-border-sm ml-2",attrs:{"role":"status"}}):_vm._e()])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }