<template>
      <!-- Navbar -->
      <div class="navigation navbar navbar-light justify-content-center py-xl-7">

        <router-link
        :to="{name: 'rooms'}"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        >
          <a :href="href" @click="navigate" class="d-none d-xl-block mb-6">
              <img src="/assets/images/logo-pen.png" width="60" style="">
              <!--<div class="badge badge-sm badge-dark" style="border-radius: 3px">BETA</div>-->
          </a>
        </router-link>

          <!-- Menu -->
          <ul class="nav navbar-nav flex-row flex-xl-column flex-grow-1 justify-content-between justify-content-xl-center py-3 py-lg-0" role="tablist">

              <!-- Invisible item to center nav vertically -->
              <li class="nav-item d-none d-xl-block invisible flex-xl-grow-1">
                  <a class="nav-link position-relative p-0 py-xl-3" href="#" title="">
                      <i class="icon-lg fe-x"></i>
                  </a>
              </li>

              <!-- Rooms -->
              <li class="nav-item mt-xl-9">
                <router-link
                :to="{name: 'rooms'}"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                    <a :href="href" @click="navigate" class="nav-link position-relative p-0 py-xl-3" :class="[{'active': isActive}]" title="Rooms" v-tooltip="'Rooms'">
                        <i class="icon-lg fe-edit"></i>
                        <div class="badge badge-dot badge-primary badge-bottom-center" v-if="isActive"></div>
                    </a>
                </router-link>
              </li>

              <!-- Stats -->
              <li class="nav-item mt-xl-9">
                <router-link
                :to="{name: 'stats'}"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                    <a :href="href" @click="navigate" class="nav-link position-relative p-0 py-xl-3" :class="[{'active': isActive}]" title="Stats" v-tooltip="'Stats'">
                        <i class="icon-lg fe-bar-chart-2"></i>
                        <div class="badge badge-dot badge-primary badge-bottom-center" v-if="isActive"></div>
                    </a>
                </router-link>
              </li>

              <!-- My Account -->
              <li class="nav-item mt-xl-9">
                <router-link
                :to="{name: 'myaccount'}"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                    <a :href="href" @click="navigate" class="nav-link position-relative p-0 py-xl-3" :class="[{'active': isActive}]" title="My Account" v-tooltip="'My Account'">
                        <i class="icon-lg fe-user"></i>
                        <div class="badge badge-dot badge-primary badge-bottom-center" v-if="isActive"></div>
                    </a>
                </router-link>
              </li>

              <!-- Goals -->
              <li class="nav-item mt-xl-9 flex-xl-grow-1">
                <router-link
                :to="{name: 'goals'}"
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                    <a :href="href" @click="navigate" class="nav-link position-relative p-0 py-xl-3" :class="[{'active': isActive}]" title="Goals" v-tooltip="'Goals'">
                        <i class="icon-lg fe-award"></i>
                        <div class="badge badge-dot badge-primary badge-bottom-center" v-if="isActive"></div>
                    </a>
                </router-link>
              </li>



              <!-- Logout -->
              <li class="nav-item mt-xl-9">
                  <a class="nav-link position-relative p-0 py-xl-3" href="#" @click.prevent="signOut" title="Logout" v-tooltip="'Logout'">
                      <i class="icon-lg fe-log-out"></i>
                  </a>
              </li>

          </ul>
          <!-- Menu -->

      </div>
      <!-- Navbar -->
</template>

<script>
import { mapActions } from 'vuex'

export default {
    computed: {

    },

    methods: {
        ...mapActions({
            signOutAction: 'auth/signOut',
        }),

        signOut() {
            this.signOutAction()
            this.$router.replace({
                name: 'signin',
            })
        }
    },
}
</script>