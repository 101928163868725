<template>
    <div class="sidebar-chat" :class="{ 'sidebar-chat-visible': showChat }">
                    <div class="d-flex h-100 flex-column">

                        <!-- Header -->
                        <div class="border-bottom py-4 py-lg-6">
                            <div class="container-fluid">
                                <div class="d-flex flex-fill align-items-center">
                                    <div class="flex-grow-1 text-center">
                                        <h2 class="font-bold mb-0 pb-0" style="line-height: 1">Chat</h2>
                                    </div>
                                    <div class="justify-content-end">
                                        <a class="nav-link text-muted px-0" href="#" @click.prevent="closeChat()">
                                            <i class="icon-md fe-x-circle"></i>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- Header -->

                        <!-- Body -->
                        <div v-if="showAd && showChat" class="container shadow-sm border-bottom">
                            <ad-sense-in-feed v-if="showChat" :slotId="slotId" layoutKey="-fb+5w+4e-db+86" :timeout="200" classNames="mb-2" />
                            <div class="text-center">
                                <a href="" @click.prevent="showAd = false" class="btn btn-secondary btn-sm btn-block"><i class="fe fe-chevron-up"></i></a>
                            </div>
                        </div>
                        <div id="messages" class="hide-scrollbar flex-fill" v-chat-scroll="{always: true, smooth: true, scrollonremoved:true, smoothonremoved: false}">
                            <div class="chat-content px-lg-4 position-relative">

                                <div class="container-xxl py-1 pt-5">

                                    <div v-if="loadingMessages" class="d-flex justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <ChatMessage 
                                        v-for="(message, index) in messages" :message="message" :key="'message' + index"
                                        @delete-message="onDeleteMessage"
                                    >
                                    </ChatMessage>
                                </div>
                            </div>
                            <VEmojiPicker
                            tabindex="-1"
                            v-show="showDialog"
                            labelSearch="Search"
                            @select="onSelectEmoji"
                            />

                            <div ref="gifPicker" class="gifPicker" v-if="gifPicker" tabindex="-1" @focusout="gifOutFocus">

                                <div class="gifSearchBar">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col-7">
                                            <input ref="gifPickerSearch" type="text" name="seach" value="" v-model="gifKeywords" @input="searchGifs" class="form-control form-control-sm" placeholder="Search GIPHY..." />
                                        </div>
                                        <div class="col">
                                            <div class="gif-poweredby">
                                                <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="gifLoading && gifKeywords"></span>
                                                <img src="/assets/images/giphy.png" class="light">
                                                <img src="/assets/images/giphy-dark.png"  class="dark">
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="gifsLoading" v-if="gifLoading && !gifs">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <template v-else>
                                <div class="gifsResults" v-if="gifs">
                                    <div class="scroller" v-if="gifs.length">
                                        <div class="row no-gutters">
                                            <div v-for="(gifResults, index) in chunkGifResults()" :key="index" class="col-6">
                                                <img v-for="gif in gifResults" :key="gif.id" :src="gif.images.downsized.url" width="100%" @click="sendGif(gif)">
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>No results found</template>
                                </div>
                                </template>
                            </div>
                        
                        <div class="chat-footer border-top py-4">
                            <div class="container-xxl">
                                <div class="ticontainer" v-if="typing">
                                <div class="tiblock">
                                    <div class="tidot"></div>
                                    <div class="tidot"></div>
                                    <div class="tidot"></div>
                                </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div v-if="!isOnline()" class="offline" style="position: absolute; top: -15px; left: 36px">
                                        <span class="badge badge-dark">You are offline</span>
                                    </div>
                                    <div class="col">
                                        <div class="input-group">
                                            
                                            <!-- Textarea -->
                                            <textarea id="room-chat-input" ref="roomchatinput" class="form-control bg-transparent border-0" placeholder="Type your message..." rows="1"
                                                style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 46px;"
                                                v-model.trim="message"
                                                @keydown="isTyping"
                                                @keydown.enter.prevent="sendMessage"
                                            ></textarea>

                                            <!-- Emoji button -->
                                            <div v-if="showGifButton" class="input-group-append">
                                                <button class="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-toggle-gif" type="button" @click="toggleGifPicker">
                                                    <svg x="0px" y="0px" viewBox="0 0 16 16" height="24" width="24" class="a8c37x1j ms05siws hr662l2t b7h9ocf4 crt8y2ji"><path d="M.783 12.705c.4.8 1.017 1.206 1.817 1.606 0 0 1.3.594 2.5.694 1 .1 1.9.1 2.9.1s1.9 0 2.9-.1 1.679-.294 2.479-.694c.8-.4 1.157-.906 1.557-1.706.018 0 .4-1.405.5-2.505.1-1.2.1-3 0-4.3-.1-1.1-.073-1.976-.473-2.676-.4-.8-.863-1.408-1.763-1.808-.6-.3-1.2-.3-2.4-.4-1.8-.1-3.8-.1-5.7 0-1 .1-1.7.1-2.5.5s-1.417 1.1-1.817 1.9c0 0-.4 1.484-.5 2.584-.1 1.2-.1 3 0 4.3.1 1 .2 1.705.5 2.505zm10.498-8.274h2.3c.4 0 .769.196.769.696 0 .5-.247.68-.747.68l-1.793.02.022 1.412 1.252-.02c.4 0 .835.204.835.704s-.442.696-.842.696H11.82l-.045 2.139c0 .4-.194.8-.694.8-.5 0-.7-.3-.7-.8l-.031-5.631c0-.4.43-.696.93-.696zm-3.285.771c0-.5.3-.8.8-.8s.8.3.8.8l-.037 5.579c0 .4-.3.8-.8.8s-.8-.4-.8-.8l.037-5.579zm-3.192-.825c.7 0 1.307.183 1.807.683.3.3.4.7.1 1-.2.4-.7.4-1 .1-.2-.1-.5-.3-.9-.3-1 0-2.011.84-2.011 2.14 0 1.3.795 2.227 1.695 2.227.4 0 .805.073 1.105-.127V8.6c0-.4.3-.8.8-.8s.8.3.8.8v1.8c0 .2.037.071-.063.271-.7.7-1.57.991-2.47.991C2.868 11.662 1.3 10.2 1.3 8s1.704-3.623 3.504-3.623z" fill-rule="nonzero"></path></svg>
                                                </button>
                                            </div>
                                            <div class="input-group-append ml-n3">
                                                <button class="btn btn-ico btn-secondary btn-minimal bg-transparent border-0" type="button" @click="toogleDialogEmoji">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smile injected-svg"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                    <!-- Submit button -->
                                    <div class="col-auto ml-n3">
                                        <button class="btn btn-ico btn-sm btn-primary rounded-circle" type="submit" @click.prevent="sendMessage" :disabled="!isOnline()">
                                            <span class="fe-send"></span>
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>

                        </div>
                        <!-- Body -->

                    </div>
     
    </div>
</template>


<script>
import Vue from 'vue'
import ChatMessage from './ChatMessage'
import VueChatScroll from 'vue-chat-scroll'
import { mapGetters } from 'vuex'
import axios from 'axios'
import { arrayToObject } from "@/utils/functions";
import _ from 'lodash'
import { VEmojiPicker } from "v-emoji-picker";
import AdSenseInFeed from '../../AdSenseInFeed.vue'

Vue.use(VueChatScroll)

export default {
    name: 'roomChat',
    components: {
        ChatMessage,
        VEmojiPicker,
        AdSenseInFeed,
    },

    props: ['room', 'showChat', 'loadChat', 'darkTheme'],

    data() {
        return {
            showAd: true,
            message: '',
            messageSending: false,
            messages: {},
            loadingMessages: true,
            unwatchLoadChatWatcher: null,
            showDialog: false,
            gifKeywords: '',
            gifPicker: false,
            typing: false,
            typingTimeout: null,
            gifLoading: false,
            giphyKey: 'luFIXlhbySOJ4HlOzbdajSysAx1j4tpK',
            showGifButton: true,
            //giphyBaseUrl: 'https://api.giphy.com/v1/gifs',
            giphyBaseUrl: 'giphy',
            gifs: false,
            slotId: false,
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user', 
        }),
    },

    watch: {
        showChat: function () {
            if (this.showChat) {
                this.$el.querySelector('#messages').scrollTop = 5000
                this.showAd = true
            }
        },
        gifPicker: function () {
            if (this.gifPicker) {
                setTimeout(() => {
                    this.$refs.gifPickerSearch.focus();
                }, 100)
                
            }
        },
        message: function () {
            if (this.message != "") {
                this.showGifButton = false
                return
            }
            this.showGifButton = true
            
        },
        darkTheme: function () {
            this.slotIdWriters = false
            setTimeout(() => {
                if (this.darkTheme == "true") {
                    this.slotId = "6090718988"
                }
                else {
                    this.slotId = "6735902034"
                }
            }, 1000)
        },
    },

    created() {

        if (this.darkTheme == "true") {
            this.slotId = "6090718988"
        }
        else {
            this.slotId = "6735902034"
        }

        // Create watcher to listen for when to loadChat and join room
        // If we do it too soon, it overwrites the parent join and screws with users joining/leaving
        this.unwatchLoadChatWatcher = this.$watch('loadChat',
            function(newValue, oldValue) 
            {
                Echo.join('Room.' + this.room.id)
                    .listen('.room.message.sent', (message) => {
                        var uid = Date.now();

                        let string = String(message.content.replace(/\\(.)/mg, "$1"))
                        let html = new DOMParser().parseFromString(string, 'text/html')

                        let giphy = html.getElementsByClassName('giphy')

                        let renderMessage = () => {
                            this.$set(this.messages, uid, message)
                            if (!this.showChat) {
                                this.$emit('updateUnreadMessageCount');
                            }
                        }

                        if (giphy.length) {
                            var img = new Image();
                            img.onload = renderMessage
                            img.src = giphy[0].src;

                            return;
                        }

                        renderMessage()
          


                    })
                    .listen('.room.message.delete', (message) => {
                        var msgIndex = _.findKey(this.messages, { 'id': message.id });
                        this.$delete(this.messages, msgIndex)
                    })
                    .listenForWhisper('typing', (e) => {
                        this.typing = e.typing;

                        // remove is typing indicator after 0.9s
                        clearTimeout(this.typingTimeout)
                        this.typingTimeout = setTimeout(() => {
                            this.typing = false
                        }, 3000);
                    })
                
                // Only watch once, unload it
                this.unwatchLoadChatWatcher();
            }
        );
        this.fetchChatMessages()

        
    },

    methods: {  

        gifOutFocus(e) {
            console.log(e)
            if (this.$refs.gifPicker != e.target && this.$refs.gifPicker != e.relatedTarget && (!e.relatedTarget || !e.relatedTarget.classList.contains('btn-toggle-gif')) ) {
                this.gifPicker = false
            }
        },

        searchGifs:  _.debounce(function() {
                this.gifLoading = true

                if (this.gifKeywords == "") {
                    this.trendingGifs()
                    return
                }

                axios.get('/giphy/search?limit=20&q=' + this.gifKeywords + '&api_key=' + this.giphyKey).then((results) => {
                    this.gifs = results.data.data
                    this.gifLoading = false
                })
                
                
            }, 500)
        ,

        sendGif(gif) {
            this.message = '<img src="'+gif.images.downsized.url+'" class="giphy">'
            this.sendMessage()
            this.toggleGifPicker()
        },

        chunkGifResults() {
            return _.chunk(this.gifs, 10);
        },

        async trendingGifs() {

            this.gifLoading = true
            let results = await axios.get('/giphy/trending?limit=20&api_key=' + this.giphyKey)
            this.gifs = results.data.data
            this.gifLoading = false
        },


        toggleGifPicker() {
            this.gifPicker = !this.gifPicker;

            if (this.gifPicker) {
                if (!this.gifs) {
                    this.trendingGifs()
                }
                this.showDialog = false
            }
            
        },

        isOnline() {
            return navigator.onLine
        },

        isTyping() {
            let channel = Echo.join('Room.' + this.room.id)

            setTimeout(function() {
                channel.whisper('typing', {
                    typing: true
                });
            }, 300);
        },

        toogleDialogEmoji() {
            this.showDialog = !this.showDialog;
            this.gifPicker = false
        },
        onSelectEmoji(emoji) {
            this.message += emoji.data;
            this.$refs.roomchatinput.focus()
            this.toogleDialogEmoji();
        },

        closeChat() {
            this.$emit('closeChat');
        },

        async sendMessage() 
        {
            this.error = false

            if (this.message == "") {
                return
            }
            // Create uid so we can update message later with message id
            var uid = Date.now();

            axios.post('/rooms/' + this.room.uuid + '/messages', {message: this.message}, {
                headers: {
                    "X-Socket-Id": Echo.socketId(),
                }
            })
            .then((res) => {
                this.$set(this.messages, uid, res.data.data)
                this.messageSending = false
            })
            .catch((err) => 
            {
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later [CH1]')
                this.error = true;
                this.messageSending = false
                // need to remove it from the last
            })
            

            // Add message straight away
            this.$set(this.messages, uid, {
                user: {
                    id: this.user.id,
                    name: this.user.name,
                },
                content: this.message,
            })
            this.message = ''
        },

        async fetchChatMessages() 
        {
            await axios.get('/rooms/' + this.room.uuid + '/messages').then((res) => 
            {
                var data = res.data.data
                this.messages = arrayToObject(data)
                setTimeout(() => {
                    this.$el.querySelector('#messages').scrollTop = 5000
                }, 1000)
                
            })
            .catch((err) => 
            {
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later [CH2]')
                this.error = true;
            })
            .then(() => {
                this.loadingMessages = false
            })
        },

        onDeleteMessage(message) {

            // Hide message straight away
            var msgIndex = _.findKey(this.messages, { 'id': message.id });
            this.$delete(this.messages, msgIndex)

            axios.delete('/rooms/' + this.room.uuid + '/messages/' + message.id, {
                headers: {
                    "X-Socket-Id": Echo.socketId(),
                }
            })
            .catch((err) => 
            {
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later [CH3]')
                this.error = true;
            })
        },

    }
}
</script>


<style>
.chat-ad {
   
    visibility: hidden;
}
.sidebar-chat-visible .chat-ad {
    visibility: visible;
}
.message-content {
    max-width: 100%;
    padding: 5px 10px;
}
.chat-sidebar.chat-sidebar-visible {
    min-width: 420px;
}
.chat-footer {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    border-top-color: #eceaea !important;
}
.chat-content {
    padding-bottom: 100px;
}
.sidebar-chat {
    visibility: hidden;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
}
.sidebar-chat-visible {
    visibility: visible;
}
#EmojiPicker {
    position: absolute;
    bottom: 72px;
    z-index: 99999;
    opacity: 1;
}
#messages {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}
span.emoji.border {
    border: 0 !important;
}

.dark .sidebar-chat {
    background: #29242a;
}
.dark .chat-footer {
    background: var(--gray-dark);
    border-top-color: #313131 !important;
}


.ticontainer {
    position: absolute;
    margin-top: -40px;
    padding-left: 20px;
}
.tiblock {
    align-items: center;
    display: flex;
    height: 17px;
}

.ticontainer .tidot {
    background-color: #90949c;
}

.tidot {
    -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
    border-radius: 2px;
    display: inline-block;
    height: 4px;
    margin-right: 2px;
    width: 4px;
}

@-webkit-keyframes mercuryTypingAnimation{
0%{
  -webkit-transform:translateY(0px)
}
28%{
  -webkit-transform:translateY(-5px)
}
44%{
  -webkit-transform:translateY(0px)
}
}

.tidot:nth-child(1){
-webkit-animation-delay:200ms;
}
.tidot:nth-child(2){
-webkit-animation-delay:300ms;
}
.tidot:nth-child(3){
-webkit-animation-delay:400ms;
}

.gifPicker {
    display: inline-flex;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeSpeed;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    overflow: hidden;
    width: 100%;
    user-select: none;
    position: absolute;
    bottom: 71px;
    min-height: 100px;
    max-height: 400px;
}
.gif-poweredby {
    text-align: right;
}
.gif-poweredby img {
    display: none;
    width: 100px;
}
.gif-poweredby img.light {
    display: inline-block;
}
.dark .gif-poweredby img.dark {
    display: inline-block;
}
.dark .gif-poweredby img.light {
    display: none;
}

.dark .gifPicker {
    background: #2d3238;
}
.gifSearchBar {
    padding: 10px;
    width: 100%;
}
.gifsResults {
    overflow-y: scroll;
}
.gifsResults img {
    padding: 2px;
    cursor: pointer;
}
.gifPicker .scroller {
    height: 100%;
}
.chat-content img.giphy {
    width: 70%;
}

@media (min-width: 576px) { 
    .sidebar-chat.sidebar-chat-visible {
        width: 420px;
    }
    .gifPicker {
        width: 325px;
        user-select: none;
        position: absolute;
        bottom: 71px;
        right: 71px;
        min-height: 100px;
        max-height: 400px;
    }
    #EmojiPicker {
        right: 71px;
    }
}

</style>