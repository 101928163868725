export const isError404 = function (res) {
    return isErrorWithResponseAndStatus(res) && 404 === res.response.status;
};

export const isError422 = function (res) {
    return isErrorWithResponseAndStatus(res) && 422 === res.response.status;
};

const isErrorWithResponseAndStatus = function (res) {
    return res.response && res.response.status;
};
