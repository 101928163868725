<template>
  <div class="wrapper-main">

    <!-- Main Content -->
    <div class="main main-visible" data-mobile-height="">

        <!-- Default Page -->
        <div class="chat flex-column justify-content-center animated fadeIn fast">
            <!-- Chat -->
            <div id="chat-1" class="chat">
                <!-- Chat: body -->
                <div class="chat-body">
                    <!-- Chat: Header -->
                    <div class="chat-header border-bottom py-4 py-lg-6 px-lg-8">
                        <div class="container-xxl">
                            <div class="row mb-4 d-xl-none">
                                <div class="col-6">
                                    <template v-if="loading.room">Loading...</template>

                                    <h6 v-else class="text-truncate mb-n1">{{ room.name }}</h6>
                                </div>
                                <div class="col-auto ml-auto">
                                    {{ displayWordCount() }}
                                </div>
                            </div>
                            <div class="row align-self-center">

                                <div class="col-4 col-xl-4 d-none d-xl-block">

                                    <div class="loader-title" v-if="loading.room">
                                        <ContentLoader
                                            width=150
                                            height=38
                                            primaryColor="#e6e6e6"
                                            secondaryColor="#ccc"
                                        >
                                            <rect x="8" y="1" rx="3" ry="3" width="120" height="10" /> 
                                            <rect x="8" y="18" rx="3" ry="3" width="52" height="6" /> 
                                        </ContentLoader>
                                    </div>
                                    <div class="media text-center text-xl-left" v-else>
                                        
                                        <ul class="list-inline mb-0 mt-4 mr-5">
                                            <li class="list-inline-item">
                                                <router-link
                                                    :to="{name: 'rooms'}"
                                                    v-slot="{ href, route, navigate }"
                                                >
                                                    <a @click="navigate" class="text-muted px-0" href="#" data-chat="open">
                                                        <i class="icon-md fe-chevron-left"></i>
                                                    </a>
                                                </router-link>
                                            </li>
                                        </ul>
                                        
                                        <div class="media-body align-self-center text-truncate">
                                            <h6 class="text-truncate mb-n1">{{ room.name }}</h6>
                                            <small class="text-muted">{{ writersCount }}</small>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="col-auto d-block d-xl-none mr-n5">
                                    <router-link
                                        :to="{name: 'rooms'}"
                                        v-slot="{ href, route, navigate }"
                                    >
                                        <a @click="navigate" class="text-muted" href="#" data-chat="open">
                                            <i class="icon-md fe-chevron-left"></i>
                                        </a>
                                    </router-link>
                                </div>

                                <div class="col-5 col-xl-4">
                                    <Timer />
                                </div>

                                <!-- Chat toolbar -->
                                <div class="col ml-auto col-xl-4 text-right">
                                    <ul class="nav justify-content-end">

                                        <li class="nav-item list-inline-item d-none d-xl-block mr-0">
                                            <a v-if="darkTheme == 'true'" class="nav-link text-muted px-3" href="#" title="Toggle Light Mode" v-tooltip="'Toggle Light Mode'" @click="changeTheme">
                                                <i class="icon-md fe-sun"></i>
                                            </a>
                                            <a v-else class="nav-link text-muted px-3" href="#" title="Toggle Dark Mode" v-tooltip="'Toggle Dark Mode'" @click="changeTheme">
                                                <i class="icon-md fe-moon"></i>
                                            </a>
                                        </li>

                                        <li class="nav-item list-inline-item d-none d-xl-block mr-0">
                                            <a class="nav-link text-muted px-3" href="#" title="Copy Writing" v-tooltip="'Copy Writing to Clipboard'" @click="copyContentWriting">
                                                <i class="icon-md fe-clipboard"></i>
                                            </a>
                                        </li>

                                        <li class="nav-item list-inline-item d-none d-xl-block mr-0">
                                            <a class="nav-link text-muted px-3" href="#" data-toggle="modal" data-target="#help-modal" title="Help" v-tooltip="'Help'">
                                                <i class="icon-md fe-help-circle"></i>
                                            </a>
                                        </li>

                                        <li class="nav-item list-inline-item d-none d-xl-block mr-0">
                                            <a class="nav-link text-muted px-3" href="#" data-toggle="modal" data-target="#share-modal" title="Share" v-tooltip="'Share Room'">
                                                <i class="icon-md fe-share"></i>
                                            </a>
                                        </li>

                                        <!-- Mobile only buttons -->
                                        <li class="nav-item list-inline-item d-block d-xl-none">
                                            <a class="nav-link text-muted px-0" @click.prevent="toggleMobileWriters">
                                                <i class="icon-md fe-users"></i> <span class="small">{{ writersNum }}</span>
                                            </a>
                                        </li>
                                        
                                        <li class="nav-item list-inline-item d-block d-xl-none">
                                            <a v-if="!room.disable_chat" class="nav-link text-muted px-3" href="#" style="position:relative;" data-chat-sidebar-toggle="#chat-1-members" title="Chat" @click.prevent="toggleChat()">
                                                <i class="icon-md fe-message-square"></i>
                                                <span v-if="unreadMessageCount" class="badge badge-primary badge-chat-notify-mobile">{{ unreadMessageCount }}</span>
                                            </a>
                                        </li>
                                        <li class="nav-item list-inline-item d-block d-xl-none">
                                            <div class="dropdown">
                                                <a class="nav-link text-muted px-0" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="icon-md fe-more-vertical"></i>
                                                </a>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="toggleMobileWriters">
                                                        Writers <span class="ml-auto pl-5 fe-users"></span>
                                                    </a>

                                                    <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="toggleChat">
                                                        Chat <span class="ml-auto pl-5 fe-message-square"></span>
                                                    </a>

                                                    <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="changeTheme()">
                                                        <template v-if="darkTheme == 'true'">
                                                            Light Mode <i class="ml-auto icon-md fe-sun"></i>
                                                        </template>
                                                        <template v-else>
                                                            Dark Mode <i class="ml-auto icon-md fe-moon"></i>
                                                        </template>
                                                    </a>

                                                    <a class="dropdown-item d-flex align-items-center" href="#" title="Copy Writing" @click.prevent="copyContentWriting">
                                                        Copy to Clipboard <i class="ml-auto pl-5 icon-md fe-clipboard"></i>
                                                    </a>

                                                    <a class="dropdown-item d-flex align-items-center" href="#" data-toggle="modal" data-target="#share-modal" title="Share">
                                                        Share <span class="ml-auto pl-5 icon-md fe-share"></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                         
                                    </ul>
                                </div>

                            </div><!-- .row -->

                        </div>
                    </div>
                    <!-- Chat: Header -->


                    <div class="editor-toolbar py-4">
                        <div class="editor-toolbar-container d-flex flex-fill align-items-center">
                            <div class="col-auto justify-content-start">
                                    <div>
                                        <button
                                            v-tooltip="'Bold'"
                                            class="menubar__button"
                                            :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().toggleBold().focus().run()"
                                        >
                                        <i class="icon fe-bold"></i>
                                        </button>

                                        <button
                                         v-tooltip="'Italic'"
                                        class="menubar__button"
                                        :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().toggleItalic().focus().run()"
                                        >
                                        <i class="icon fe-italic"></i>
                                        </button>

                                        <button
                                        v-tooltip="'Underline'"
                                        class="menubar__button"
                                        :class="{ 'is-active': editor.isActive('underline') }" @click="editor.chain().toggleUnderline().focus().run()"
                                        >
                                        <i class="icon fe-underline"></i>
                                        </button>
                                        <button
                                         v-tooltip="'Undo'"
                                        class="menubar__button"
                                        @click="editor.chain().toggleUndo().focus().run()"
                                        >
                                        <i class="icon fe-corner-up-left"></i>
                                        </button>
                                        <button
                                         v-tooltip="'Redo'"
                                        class="menubar__button"
                                        @click="editor.chain().toggleRedo().focus().run()"
                                        >
                                        <i class="icon fe-corner-up-right"></i>
                                        </button>
                                    </div>
                            </div>
                            <div class="col justify-content-end text-right">

                     
                                <v-popover
                                    offset="5"
                                    popoverClass="editor-settings"
                                >
                                    <button class="menubar__button tooltip-target">
                                        <i class="icon fe-settings" style="margin-right: 2px;"></i> <span style="line-height: 15px">Customize</span> 
                                    </button>

                                    <template slot="popover">
                                        <select name="font" class="form-control form-control-sm" v-model="editorSettings.fontFamily">
                                            <option value="Roboto">Font Family</option>
                                            <option>Roboto (Default)</option>
                                            <option value="Arial">Arial</option>
                                            <option>Courier</option>
                                            <option>Comic Sans MS</option>
                                            <option>Georgia</option>
                                            <option>Tahoma</option>
                                            <option value="Times New Roman">Times</option>
                                            <option>Verdana</option>
                                            <option value="OpenDyslexic">Dyslexic</option>
                                        </select>
                                        <div class="row no-gutters align-items-center pt-3">
                                            <label class="col-sm-8 col-form-label">Font Size</label>
                                            <div class="col-sm-4 text-right">
                                                <i class="icon fe-minus" @click="editorSettings.fontSize--"></i> <i class="icon fe-plus" @click="editorSettings.fontSize++"></i>
                                            </div>
                                        </div>
                                        <div class="row no-gutters align-items-center">
                                            <label class="col-sm-8 col-form-label">Line Height</label>
                                            <div class="col-sm-4 text-right">
                                                <i class="icon fe-minus" @click="editorSettings.lineHeight--"></i> <i class="icon fe-plus" @click="editorSettings.lineHeight++"></i>
                                            </div>
                                        </div>
                                        <div class="row no-gutters align-items-center">
                                            <label class="col-sm-8 col-form-label">Paragraphs</label>
                                            <div class="col-sm-4 text-right">
                                                <i class="icon fe-minus" @click="decreaseParagraphSpacing()"></i> <i class="icon fe-plus" @click="increaseParagraphSpacing()"></i>
                                            </div>
                                        </div>
                                        <div class="row no-gutters align-items-center">
                                            <label class="col-sm-8 col-form-label">Text Indent</label>
                                            <div class="col-sm-4 text-right">
                                                <i class="icon fe-minus" @click="decreaseTextIndent()"></i> <i class="icon fe-plus" @click="increaseTextIndent()"></i>
                                            </div>
                                        </div>
                                        <div class="text-center pt-3">
                                            <a href="" @click.prevent="resetEditorSettings()">Reset</a>
                                        </div>
                                    </template>
                                </v-popover>


                            </div>
                        </div>
                        
                    </div>

                    <!-- Chat: Content-->
                    <div class="chat-content chat-content-main px-lg-8">
                        <div class="editor" :class="editorClasses" :style="editorStyles">
                            <editor-content :editor="editor" />
                        </div>
                    
                    </div>

                </div>


            </div>
        </div>
        <!-- Default Page -->

    </div>
    <!-- Main Content -->

    <!-- Sidebar -->
    <div class="sidebar" ref="sidebar" :class="{ 'hide-panel': hidePanel }" :style="{zIndex: sideBarZIndex}">
        <Writers 
            :room="room" 
            :users="users" 
            :wordCounts="wordCounts" 
            :loading="loading" 
            @toggleSidebar="toggleSidebar"
            :unreadMessageCount="unreadMessageCount"
            @toggleChat="toggleChat"
            @toggleMobileWriters="toggleMobileWriters"
            :adText="adText"
            :randomAdNum="randomAdNum"
            :darkTheme="darkTheme"
        ></Writers>          
    </div>   
    <Chat 
        :room="room" 
        @closeChat="toggleChat" 
        @updateUnreadMessageCount="updateUnreadMessageCount"
        :showChat="showChat" 
        :loadChat="loadChat"
        v-if="!room.disable_chat"
        :darkTheme="darkTheme"
        >
    </Chat>



    <div class="modal fade" id="help-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <div class="media flex-fill">
                        <div class="icon-shape rounded-lg bg-primary text-white mr-5">
                            <i class="fe-help-circle"></i>
                        </div>
                        <div class="media-body align-self-center">
                            <h5 class="modal-title">Help</h5>
                            <p class="small">Information on how sprints work</p>
                        </div>
                    </div>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    
                    <div class="form-group">
                        <strong class="small font-bold">WHAT'S A WORD SPRINT?</strong>
                        <p>A specific amount of time during which you focus specifically on writing as many words as possible. No distractions. No stopping. No editing! The goal is to write as much as you can within the given timeframe.
                             You can set your own time limit, use the universal timer, or switch off the timer altogether and just let the words flow limitlessly!</p>

                    </div>

                    <div class="form-group">
                        <strong class="small font-bold">IS MY WORK PRIVATE?</strong>

                        <p>Yes. Always. We take privacy and security seriously. Only your word count is visible to other writers in a sprint room. The words you write are never sent to ohwrite; they are stored on your computer and visible only to you.</p>

                    </div>

                    <div class="form-group">
                        <strong class="small font-bold">HOW DO SPRINTS WORK?</strong>

                        <p>You can create as many rooms as you like and share them with writing buddies. Each writer in a sprint room can see the others'
                       word counts and everyone can choose their own timer options. There is also a discreet chat window. If you move to
                        a different sprint room, the words you've written will follow you.</p>

                        <p>You earn stars!<br>
                            <span class="star star-100 d-inline-block"></span> 100 words<br>
                            <span class="star star-1000 d-inline-block mt-n2"></span> 1000 words<br>
                        </p>
                        
                        <p>The progress bars count up to 100 and then start over for the next star.
                        There's a five-minute suggested break period between sprints, but if you're on a roll, feel free to keep writing!</p>
                    </div>


                </div>


            </div>
        </div>
    </div>

    <div class="modal fade" id="share-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <div class="media flex-fill">
                        <div class="icon-shape rounded-lg bg-primary text-white mr-5">
                            <i class="fe-share"></i>
                        </div>
                        <div class="media-body align-self-center">
                            <h5 class="modal-title">Share Sprint Room</h5>
                            <p class="small">Invite friends to write with you</p>
                        </div>
                    </div>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    
                    <p class="small">Simply copy and paste the link below and share with friends. Anyone with this link can join the room.</p>

                    <div class="form-group text-center">
                        <input type="text" id="share-link" name="share" class="form-control" :value="getCurrentUrl()" @focus="$event.target.select()">
                        <a href="" @click.prevent="copyShareLink" class="mt-3 d-block">Copy Link <i class="fe-copy"></i></a>
                    </div>

                </div>
                <div class="modal-footer">
                    <a :href="getTwitterShareLink()" target="_blank" class="btn btn-lg btn-block btn-primary d-flex align-items-center">
                        Tweet <i class="fe-twitter ml-auto"></i>
                    </a>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="offline" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

                <div class="modal-body">
                    You are currently not connected to the room.<br> 
                    If this is an ongoing issue please contact us at <a href="mailto:feedback@ohwrite.co">feedback@ohwrite.co</a> so we can help fix it.
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-lg btn-block btn-primary d-flex align-items-center" @click="reconnectRoom()">
                        Attempt Reconnect Now
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="copyModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <div class="media flex-fill">
                        <div class="icon-shape rounded-lg bg-primary text-white mr-5">
                            <i class="fe-clipboard"></i>
                        </div>
                        <div class="media-body align-self-center">
                            <h5 class="modal-title">Copy and Pasting</h5>
                        </div>
                    </div>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>The best way to get your text copied in the way you have formatted with extra spaces and paragraphs is to use our Copy Function <i class="icon-md fe-clipboard"></i> at the top of the screen.</p>
                    <p>Note: This copies the whole document and not just the text you have selected.</p>


                    <p>If the formatting is not correct when you paste please try the following:
                    You can right click on the target document and choose <strong>Paste and Match Style</strong> from the list.</p>
                    
                    <div class="small">
                        <strong>Tip:</strong> Want indenting? This can be setup your word processing software.
                    </div>

                </div>
                <div class="modal-footer text-center">
                    <div class="form-group form-check mr-auto pb-2">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" @click="copyWarningPreference()" :checked="copyWarningHide == 'true'">
                        <label class="form-check-label" for="exampleCheck1"> Don't show me this message again</label>
                    </div>
                    <button type="button" class="btn btn-lg btn-primary d-flex align-items-center text-center" data-dismiss="modal">
                        OK
                    </button>
                </div>

            </div>
        </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import EventBus from '@/utils/eventBus'
import Writers from './View/Writers'
import Chat from './View/Chat'
import { ContentLoader } from 'vue-content-loader'
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { arrayToObject } from "@/utils/functions";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Code from "@tiptap/extension-code";
import Typography from "@tiptap/extension-typography";
import { markInputRule } from '@tiptap/core'
import {
  InputRule,
} from 'prosemirror-inputrules'

import { Extension } from '@tiptap/core'

const firstInParagraphRule = new InputRule(/^[a-z]{1}$/, (state, match, start, end) => {
    return state.tr.replaceWith(start, end, state.schema.text(match.input.toUpperCase()))
})

const lowerCaseAfterStopRule = new InputRule(/([a-z](\.|\?|\!){1}\s([a-z]{1}))$/, (state, match, start, end) => {
    return state.tr.replaceWith(end, end, state.schema.text(match[3].toUpperCase()))
})

const badPuncRule = new InputRule(/ ([,\.!?:]) ?$/, (state, match, start, end) => {
    return state.tr.replaceWith(start, end, state.schema.text(match[1]))
})


const CustomExtension = Extension.create({
  name: 'customExtension',

  addInputRules() {
    return [
      firstInParagraphRule,
      lowerCaseAfterStopRule,
      badPuncRule

    ]
  },
})


export default 
{
    name: "roomsView",

    components: {
        ContentLoader,
        Writers,
        Timer: () => import(/* webpackPrefetch: true */ './View/Timer'),
        Chat,
        Editor,
        EditorContent,
    },

    data() {
        return {
            buttonSelected: false,
            content: '',
            loading: {
                room: true,
                users: true,
            },
            copyWarningHide: false,
            room: {
                id: '',
                uuid: '',
                name: '',
                timer_length: '',
            },
            users: {},
            wordCounts: {},
            /* For storing words actually typed */
            wordsTyped: {
                count: 0,
                text: null,
            },
            editorSettings: {}, // Font size/weight paragraphs etc
            typedWords: null,
            text: false,
            darkTheme: false,
            editor: null,
            keepInBounds: true,
            showChat: false,
            echo: null,
            loadChat: false,
            unreadMessageCount: 0,
            hidePanel: false,
            socketConnectionStatus: null,
            sideBarZIndex: 0,
            randomAdNum: 0,
            adText: [
                "Royalties, ranks and reviews all in one place",
                "See your book ranks in real time across multiple regions",
                "Unlock the power of Amazon Author Central with in depth data analysis",
            ],
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
        writersNum() {
            return _.size(this.users)
        },

        writersCount() {
            var count = _.size(this.users)
            return count + ' writer' + (count != 1 ? 's' : '')
        },
        editorClasses() {
            var result = [];
            result.push(this.getTextIndentClass());
            result.push(this.getParagraphClass());
            return result
        },
        editorStyles() {
            var result = [];
            result.push({'fontFamily': this.editorSettings.fontFamily})
            result.push({'fontSize': this.editorSettings.fontSize + 'px'})
            result.push({'lineHeight': this.editorSettings.lineHeight + 'px'})
            return result
        },
    },

    watch: {
        /**
         * Watch if user changes, font size, font family etc.
         */
        'editorSettings': {
            handler: function (val, oldVal) {
                if (typeof oldVal.fontSize !== 'undefined') {
                    localStorage.setItem('editorSettings', JSON.stringify(val));
                }
            },
            deep: true
        },
    },
    mounted() {

    },

    beforeMount() {
        // Load default settings
        this.resetEditorSettings()
        this.loadEditorSettings()

        /*
        EventBus.$on('SOCK_STATE_CHANGE', (state) => {
            this.socketConnectionStatus = state.current;

            if (state.current != "connected") {
                $('#offline').modal('show')
            }
            else {
                $('#offline').modal('hide')
            }

            if (state.previous == "failed" || state.previous == "unavailable") {
                if (state.current == "connected") {
                    this.reconnectRoom()
                }
            }
        })
        */
    },

    methods: 
    {

        displayWordCount() {
            var count = this.wordCounts[this.user.id] ? this.wordCounts[this.user.id] : 0;
            return Number(count).toLocaleString() + ' word' + (count != 1 ? 's' : '');
        },
        
        changeTheme(event) {
            this.darkTheme = this.darkTheme == "true" ? "false" : "true"
            if (this.darkTheme == "true") {
                document.getElementById('theme-dark').media='all';
                document.getElementById('theme-light').media='none';
                document.querySelector('body').classList.add('dark');
            }
            else {
                document.getElementById('theme-dark').media='none';
                document.getElementById('theme-light').media='all';
                document.querySelector('body').classList.remove('dark');
            }
            localStorage.setItem('darkTheme', this.darkTheme)
        },

        copyWarningPreference(event) {
            this.copyWarningHide = this.copyWarningHide == "true" ? "false" : "true"
            localStorage.setItem('copyWarningHideNew', this.copyWarningHide)
        },


        getTwitterShareLink() {
            var text = encodeURI("I'm sprinting on @ohwriteapp. Come join me in my sprinting room.")
            var url = encodeURI(this.getCurrentUrl())
            var hashtags = "amwriting,ohwrite"
            return "https://twitter.com/intent/tweet?text=" + text + "&url=" + url + '&hashtags=' + hashtags
        },
        toggleMobileWriters() {
            this.sideBarZIndex = (this.sideBarZIndex == 0 ? 9999 : 0)
        },
        updateOnlineStatus(e) {
            const {
                type
            } = e;
            this.onLine = type === 'online';

        },

        reconnectRoom() {
            this.$router.go()
        },

        getTextIndentClass() {
            return 'text-indent-' + this.editorSettings.textIndent
        },
        increaseTextIndent() {
            this.editorSettings.textIndent++
            if (this.editorSettings.textIndent > 3) {
                this.editorSettings.textIndent = 0;
            }
        },
        decreaseTextIndent() {
            this.editorSettings.textIndent--
            if (this.editorSettings.textIndent < 0) {
                this.editorSettings.textIndent = 3;
            }
        },

        getParagraphClass() {
            return 'text-margin-' + this.editorSettings.paragraphSpacing
        },
        increaseParagraphSpacing() {
            this.editorSettings.paragraphSpacing++
            if (this.editorSettings.paragraphSpacing > 5) {
                this.editorSettings.paragraphSpacing = 0;
            }
        },
        decreaseParagraphSpacing() {
            this.editorSettings.paragraphSpacing--
            if (this.editorSettings.paragraphSpacing < 0) {
                this.editorSettings.paragraphSpacing = 5;
            }
        },

        resetEditorSettings() {
            this.editorSettings = {
                textIndent: 1,
                fontFamily: "Roboto",
                fontSize: 15,
                lineHeight: 24,
                paragraphSpacing: 2,
                autocaps: true,
                smartQuotes: false,
            }
        },


        getCurrentUrl() {
            return window.location.href;
        },

        copyShareLink () {
          let testingCodeToCopy = document.querySelector('#share-link')
          testingCodeToCopy.select()

          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            if (successful) {
                Vue.$toast.success('Share link was copied to your clipboard')
            }
            else {
                Vue.$toast.error('Ooops! Unable to copy')
            }
          } catch (err) {
            Vue.$toast.error('Ooops! Unable to copy')
          }

          /* unselect the range */
          window.getSelection().removeAllRanges()
        },

        copyContentWriting() 
        {
            var str = document.querySelector('.ProseMirror').innerHTML
            let plainStr = localStorage.getItem('writingText')
            
            function listener(e) {
                e.clipboardData.setData("text/html", str);
                e.clipboardData.setData("text/plain", plainStr);
                e.preventDefault();
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);

            this.copyWarningHide = this.copyWarningHide == "true" ? "false" : "true"
            localStorage.setItem('copyWarningHideNew', this.copyWarningHide)
    
            Vue.$toast.success('Your writing has been copied to clipboard')
        },

        /**
         * Fetch Room
         * 
         * When loading the page for the first time, we need to get name and other details
         */
        async fetchRoom() 
        {
            // If id already exists, it means the data exists already from router
            // no need to load room data
            if (this.$route.params.id) {
                this.loading.room = false;
                return
            }
            await axios.get('/rooms/' + this.$route.params.uuid).then((res) => 
            {
                this.room = res.data.data;
                this.loading.room = false;
            })
            .catch((err) => 
            {
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                this.$router.replace({ name: 'rooms' })
                this.error = true;
            });
        },

        /**
         * Connect Room
         * 
         * This is so we connect websockets so we know who else is in the room
         */
        async connectRoom() 
        {
            window.Echo.join('Room.' + this.room.id)
                .here(users => {
                    this.users = Object.assign({}, this.users, arrayToObject(users))
                    // Let's load the chat 
                    this.loadChat = true;
                    this.loading.users = false;
                    // Let the room know who is in there
                    Echo.join('Room.' + this.room.id)
                        .whisper('room:users', _.keys(this.users))
                })
                .joining(user => {
                    this.$set(this.users, user.id, user);
                    
                    Echo.join('Room.' + this.room.id)
                        .whisper('writing', {
                            id: this.user.id,
                            name: this.user.name,
                            wordCount: this.wordCount(),
                        });
                })
                .leaving(user => {
                    this.$delete(this.users, user.id);
                    this.$delete(this.wordCounts, user.id);
                })
                .listenForWhisper('writing', (e) => {
                    this.$set(this.wordCounts, e.id, e.wordCount);
                })
                .whisper('room:member_join', {
                    room: {
                        id: this.room.id,
                    },
                    user: this.user
                })
        },

        /**
         * Calculate Word Count
         * 
         * Count the amount of words the user has typed
         */
        wordCount(text) {
            if (!text) {
                text = this.text
            }

            if (!text) {
                return 0;
            }

            var words = this.calcWords(text)

            return words
        },

        calcWords(string)
        {
            let r1 = new RegExp('[\u3000-\u4DFF]','g');
            let r2 = new RegExp('[\u4E00-\u9FFF]','g');
            let r3 = new RegExp('[\u0E00-\u0E7F]','g');
            string = string.replace(/。/g, "");
            string = string.replace(r1,' {PNK} ');
            string = string.replace(r2,' {CJK} ');
            string = string.replace(r3,' {THI} ');
            //string = string.replace(/(<([^>]+)>)/ig,”") ;
            //string = string.replace(/(\(|\)|\*|\||\+|\”|\’|_|;|:|,|\.|\?)/ig," ") ;
            string = string.replace(/\s+/ig," ");
            //string = string.replace(/_+/ig," ");
            var a = string.split(/[\s+|\\|\/]/g);
            var count = 0;
            var pnkCounter = 0;
            var thiCounter = 0;
            for (var i=0;i<a.length;i++){
                if (a[i]=='{PNK}'){
                    pnkCounter++;
                }else if(a[i]=='{THI}'){
                    thiCounter++;
                }else if (a[i].length>0){
                    count++;
                }
            }
            count += Math.ceil(pnkCounter/3) + Math.ceil(thiCounter/4);
            return count;
        },

        updateWordsTyped(event) {
    
            // Add to words typed
            this.wordsTyped.text =+ event.key;
            var newWordCount = this.wordCount(this.wordsTyped.text)

            if (newWordCount > this.wordsTyped.count) {
                /*
                Echo.join('Room.' + this.room.id)
                    .whisper('room:user_new_word', {roomUid: this.room.uuid, userId: this.user.id});
                    */

                var currentWordCount = parseInt(localStorage.getItem('wordCount'));
                localStorage.setItem('wordCount', 0);
                
            
                if (!Number.isInteger(currentWordCount)) {
                    currentWordCount = 0;
                }
                localStorage.setItem('wordCount', parseInt(currentWordCount) + 1);

            }
            this.wordsTyped.count = newWordCount

        },

        /**
         * Writing
         * 
         * Whenever the user is typing, we want to record the word count and store it
         */
        writing(text) {
            this.text = text;
            localStorage.setItem('writingText', text);
            localStorage.setItem('writingHTML', this.editor.getHTML());

            let newWordCount = this.wordCount()

            if (_.has(this.wordCounts, this.user.id)) {
                var currentWordCount = this.wordCounts[this.user.id]
                // If actual word count hasn't changed, no need to notify anyone
                if (currentWordCount == newWordCount) {
                    return
                }
                
            }
            this.$set(this.wordCounts, this.user.id, newWordCount);
            Echo.join('Room.' + this.room.id)
                .whisper('writing', {
                    id: this.user.id,
                    name: this.user.name,
                    wordCount: newWordCount,
                });
        },

        toggleChat() {
            this.showChat = (this.showChat ? false : true)
            if (this.showChat) {
                $zopim.livechat.button.hide();
            }
            else {
                $zopim.livechat.button.show();
            }
            if (this.showChat) {
                this.unreadMessageCount = 0;
            }
        },

        toggleSidebar() {
            this.hidePanel = (this.hidePanel ? false : true)
        },

        updateUnreadMessageCount() {
            this.unreadMessageCount++;
        },

        setPageTitle() {
            document.title = 'Sprint Room, ohWrite'
            if (this.room.name) {
                document.title = this.room.name + ', Sprint Room, ohWrite'
            }
        },

        loadEditorSettings() {
            var savedEditorSettings = localStorage.getItem('editorSettings')
            if (savedEditorSettings !== null) {
                this.editorSettings = Object.assign({}, this.editorSettings, JSON.parse(savedEditorSettings))
            }
        },
    },

    created() {

        var darkTheme = localStorage.getItem('darkTheme');
        this.darkTheme = darkTheme

        this.randomAdNum = Math.floor(Math.random() * this.adText.length)
        var copyWarningHide = localStorage.getItem('copyWarningHide');
        this.copyWarningHide = copyWarningHide

        document.body.addEventListener("copy", (e) => {
            if (!this.copyWarningHide) {
                $('#copyModal').modal('show')
            }
        });

        this.loadEditorSettings()
        
        this.setPageTitle()

        document.title = 'Sprint Room, ohwrite'

        window.addEventListener('beforeunload', this.beforeUnload, false)

        var localStorageText = localStorage.getItem('writingText');
        var localStorageHTML = localStorage.getItem('writingHTML');
        if (localStorageText === null) {
            localStorageText = ''
        }
        this.text = localStorageText;

        this.room = this.$route.params
        this.fetchRoom().then(() => {
            this.setPageTitle()

            this.connectRoom().then(() => {
                this.writing(localStorageText);
            })
        })


        this.editor = new Editor({
            autofocus: "end",
            parseOptions: {
                preserveWhitespace: true,
            },
            extensions: [
                StarterKit,
                Document,
                Paragraph,
                Text,
                Code,
                Typography,
                CustomExtension
            ],
            editorProps: {
                handleKeyPress: (view, event) => {
                  this.updateWordsTyped(event)
                },
            },
            content: localStorageHTML,
            
            onUpdate: ({ editor }) => {
                this.writing(this.editor.getText())
            }

        });

    
        window.addEventListener('offline', function () {
            //$('#offline').modal('show')
        });

    },

    beforeDestroy: function () {
        window.removeEventListener('offline', function () {
            //$('#offline').modal('show')
        });
        window.removeEventListener('beforeunload', this.beforeUnload)
        //this.editor.destroy()
        Echo.leave('Room.' + this.room.id);
    },
}
</script>


<style scoped>
    .main {
        order: 2;
    }
    .sidebar {
        order: 3;
        transition: right 0.3s;
        right: 0;
    }

    @media (max-width: 575.98px) { 
        .sidebar {
            position: fixed;
            height: 100%;
        }
    }

    .loader-title {
        height: 38px;
        width: 150px;
        margin-top: 7px;
    }
    .menubar__button {
        font-weight: 700;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        background: transparent;
        border: 0;
        color: #333;
        padding: .2rem .5rem;
        margin-right: .2rem;
        border-radius: 3px;
        cursor: pointer;
    }
    .menubar__button:hover {
        color: #000;
    }
    .dark .menubar__button {
        color: var(--gray)
    }
    .menubar__button span {
        font-size: 12px;
        margin-left: 4px;
        font-weight: normal;
    }
    .hide-panel {
        position: fixed;
        right: -370px;
        z-index: 99;
    }
    .text-indent-0 {
        text-indent: 0px;
    }
    .text-indent-1 {
        text-indent: 50px;
    }
    .text-indent-2 {
        text-indent: 100px;
    }
    .text-indent-3 {
        text-indent: 150px;
    }

    @media (max-width: 575.98px) { 
        .text-indent-0 {
            text-indent: 0px;
        }
        .text-indent-1 {
            text-indent: 20px;
        }
        .text-indent-2 {
            text-indent: 40px;
        }
        .text-indent-3 {
            text-indent: 60px;
        }
    }
</style>
<style>
.editor-settings .tooltip.popover .popover-inner {
    margin-bottom: -22px !important;
    color: #7f808c !important;
}
.editor-settings label {
    color: #7f808c !important;
}
.text-margin-0 p {
    margin-bottom: 0rem;
}
.text-margin-1 p {
    margin-bottom: 0.5rem;
}
.text-margin-2 p {
    margin-bottom: 1rem;
}
.text-margin-3 p {
    margin-bottom: 1.5rem;
}
.text-margin-4 p {
    margin-bottom: 2rem;
}
.text-margin-5 p {
    margin-bottom: 2.5rem;
}
.text-margin-6 p {
    margin-bottom: 3rem;
}
.chat-content.chat-content-main {
    -ms-overflow-style: auto !important;
    scrollbar-width: thin !important;
}
.chat-content-main {
    background:#f1f1f1;
}
.editor .ProseMirror {
    color: #000;
    min-height: 500px;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    padding: 40px;
    border: 1px solid #ececec;
    box-shadow: -1px 0px 5px rgba(0,0,0,0.03);
}
.editor .ProseMirror p {
    font-size: inherit;
}
.editor-toolbar {
    background: #f1f1f1;
}
.editor-toolbar-container {
    max-width: 800px;
    margin: 0 auto;
}
.ProseMirror:focus, .ProseMirror-focused {
    outline: none !important;
}
.tooltip.editor-settings {
    box-shadow: 0px 9px 22px rgba(0,0,0,0.20);
    max-width: 280px;
}
.tooltip.editor-settings:focus {
    outline: none;
}
.editor-settings .tooltip-inner {
    width: 280px;
    text-align: initial;
    padding-bottom: 0 !important;
}
.editor-settings i {
    padding: 5px;
    cursor: pointer;
    color: #333;
}
.editor-settings i:hover {
    color: #000;
}


.dark .chat-content-main {
    background: var(--gray-dark);
}
.dark .editor .ProseMirror {
    background: #29242a;
    color: #aab0b7;
    border:none;
    box-shadow: none;
}
.dark .editor-toolbar {
    background: var(--light)
}
.dark .editor-settings i {
    color: var(--gray)
}
.dark .editor-settings label {
    font-weight: bold
}

.badge-chat-notify-mobile {
    position: absolute;
    width: 7px;
    height: 7px;
    padding: 0;
    margin-top: 1px;
    margin-left: -5px;
    text-indent: 10000px;
}
</style>
