<template>
    <div class="animated fadeIn fast">
        <!-- Heading -->
        <h1 class="font-bold text-center">Forgot Password</h1>

        <div v-if="success" class="animated fadeIn fast">

            <p class="text-center">
                We have emailed your password reset link!
            </p>
  
            <p class="text-center">
                <router-link :to="{name: 'signin'}">Sign in</router-link>
            </p>
        </div>
        <div v-else>
            <!-- Text -->
            <p class="text-center mb-6">Enter your email address to reset password.</p>

            <!-- Form -->
            <ValidationObserver ref="form" v-slot="{ passes }">
                <form class="mb-6" @submit.prevent="passes(submit)">

                    <!-- Email -->
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                        <div class="form-group">
                            <label for="email" class="sr-only">Email Address</label>
                            <input type="email" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="email" placeholder="Enter your email" v-model="form.email">
                            <div class="invalid-feedback" v-for="(error, index) in errors" :key="'email' + index">
                                {{ error }}
                            </div>
                        </div>
                    </ValidationProvider>

                    <!-- Submit -->
                    <button class="btn btn-lg btn-block btn-primary" :class="[{'disabled': sending}]" type="submit" :disable="sending">Send Reset Link <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div></button>
                </form>
            </ValidationObserver>

            <!-- Text -->
            <p class="text-center">
                Already have an account? <router-link :to="{name: 'signin'}">Sign in</router-link>.
            </p>
        </div>
    </div>
</template>


<script>
import Vue from 'vue'
import { isError422 } from "../utils/response";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import axios from 'axios'
import _ from 'lodash'

extend('email', {
  ...email,
  message: 'Invalid email address'
});
extend('required', {
  ...required,
  message: 'Required'
});

export default {
    name: 'forgotPassword',

    components: {
        ValidationProvider,
        ValidationObserver
    },

    data() {
        return {
            form: {
                email: '',
            },
            error: false,
            errors: null,
            sending: false,
            success: false,
        }
    },

    methods: {

        // /password/email
        async submit() {
            this.sending = true;
            this.error = false;

            await axios.post('/password/email', this.form).then((res) => 
            {
                this.success = true
            })
            .catch((err) => 
            {
                if (isError422(err)) {
                    const errors = err.response.data.errors;
                    this.$refs.form.setErrors(errors);
                    return;
                }
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later', {
                    duration: 6000,
                });
                this.error = true;
            })
            .then(() => {
                this.sending = false;
            });

        },

    }
}
</script>
