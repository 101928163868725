<template>
  <div class="layout">
      <Nav/>
      <slot />
  </div>
</template>

<script>
import Vue from 'vue'
import Nav from '@/components/Nav'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    components: {
      Nav
    },

    created() {
        setInterval(() => {

            var wordCount = parseInt(localStorage.getItem('wordCount'));
            if (!Number.isInteger(wordCount)) {
                wordCount = 0;
            }
            if (wordCount == 0 || !navigator.onLine) {
                return
            }
            // Let's clear words typed
            localStorage.setItem('wordCount', 0);

            axios.post('/user/wordcount', {count: wordCount}).then((res) => 
            {

            })
            .catch((err) => 
            {
                var currentWordCount = localStorage.getItem('wordCount');
                if (currentWordCount === undefined) {
                    currentWordCount = 0;
                }
                // If it fails to update, we'll need to put the wordcount back
                localStorage.setItem('wordCount', parseInt(currentWordCount) + wordCount);
            })

        }, 30000);
    },
}
</script>

<style>
.wrapper-main {
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
}

.btn-primary {
    transition: background-image 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(150deg, #173df7 0%, #01aefa 51%, #01c3fb 100%);
    
}
.progress-bar {
  transition: background-image 0.5s;
  background-image: linear-gradient(150deg, #173df7 0%, #01aefa 51%, #01c3fb 100%);
}
.btn.btn-primary:hover {
    background-position: right center;
    border-color: #01c3fb;
}
.text-primary{
    color: #173df7;
}
.fill-primary, .bg-primary {
    background: #173df7;
}

.dark .main .chat-content {
    background: var(--gray-dark);
}

::-webkit-scrollbar
{
    scrollbar-width: thin !important;
    display: block !important;
}
::-webkit-scrollbar {
    width: 8px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); 
    border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;
}

.dark .tooltip.popover .popover-inner {
    background: #29242a;
}

@media only screen and (max-width: 600px) {
  .navigation {
      position: fixed;
      z-index: 99;
      bottom: 0;
      width: 100%;
  }
}
</style>