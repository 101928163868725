<template>
       <div class="d-flex flex-column h-100" :class="{ 'hidden-sidebar': hidePanel }">
        <a v-if="hidePanel" href="" @click.prevent="toggleSidebar" class="open-sidebar-link"><i class="icon-md fe-chevron-left"></i></a>
        <div class="hide-scrollbar d-flex h-100">
            <div class="container-fluid py-6">

                <div class="d-flex flex-fill align-items-center mb-5">
                    <div class="justify-content-start d-none d-md-block">
                        <a href="" @click.prevent="toggleSidebar" class="text-muted"><i class="icon-md fe-chevron-right"></i></a>
                    </div>
                    <div class="flex-grow-1 text-center">
                        <h2 class="font-bold mb-0 pb-0" style="line-height: 1">Writers</h2>
                        <span>{{ totalRoomWordsCount }}</span>
                    </div>
                    <div class="justify-content-start d-block d-md-none">
                        <a href="" @click.prevent="toggleMobileWriters" class="text-muted"><i class="icon-md fe-x-circle"></i></a>
                    </div>
                    <div class="justify-content-end d-none d-md-block">
                        <a v-if="!room.disable_chat" class="nav-link text-muted px-3" href="#" style="position:relative;" data-chat-sidebar-toggle="#chat-1-members" title="Chat" @click.prevent="toggleChat()">
                            <i class="icon-md fe-message-square" style="vertical-align: -3px"></i> Chat
                            <span v-if="unreadMessageCount" class="badge badge-primary badge-chat-notify">{{ unreadMessageCount }}</span>
                        </a>
                    </div>
                </div>

                <!-- Chats -->
                <nav class="nav d-block list-discussions-js mb-n6">

                    <div v-if="loading.users">
                        <div v-for="n in 2">
                            <a class="text-reset nav-link p-0 mb-6 animated fadeIn faster">
                                <div class="card card-active-listener">
                                    <div class="card-body">
                                        <div class="loader-content">
                                            <ContentLoader
                                                width=300
                                                height=50
                                                primaryColor="#f3f3f3"
                                                secondaryColor="#ecebeb"
                                                >
                                                <rect x="55" y="10" rx="3" ry="3" width="80%" height="6" /> 
                                                <rect x="55" y="23" rx="3" ry="3" width="80%" height="6" /> 
                                                <circle cx="27" cy="22" r="20" />
                                            </ContentLoader>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div v-else v-for="(user, index) in userList" :key="'user' + index">
                        <!-- Chat link -->
                        <a class="text-reset nav-link p-0 mb-6 animated fadeIn faster">
                            <div class="card card-active-listener">
                                <div class="card-body">

                                    <div class="media">
                                        <Avatar :user="user" classNames="avatar mr-5"></Avatar>
                                        
                                        <div class="media-body overflow-hidden">
                                            <div class="d-flex align-items-center mb-2">
                                                <h6 class="text-truncate mb-0 mr-auto" style="padding-right: 5px">{{ user.name }}</h6>
                                                <p class="small text-muted text-nowrap ml-4">{{ displayWordCount(user) }}</p>
                                            </div>
                                            <WriterProgressBar :user="user" :wordCount="wordCount(user)" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </a>
                        <!-- Chat link -->
                        <div v-if="showAd && slotIdWriters && (index+1) == 3" class="text-center mb-2">
                            <a href="" class="text-muted small" @click.prevent="showAd = false"><i class="fe fe-x"></i> Hide Ad</a>
                        </div>
                        <ad-sense-in-feed v-if="showAd && slotIdWriters && (index+1) == 3" :slotId="slotIdWriters" layoutKey="-fb+5w+4e-db+86" :timeout="200" classNames="mb-5" />
                    </div>
                        <div v-if="showAd && !loading.users" class="text-right mb-2">
                            <a href="" class="text-muted small" @click.prevent="showAd = false"><i class="fe fe-x"></i> Hide Ad</a>
                        </div>
                        <ad-sense-in-feed v-if="showAd && !loading.users && slotIdWriters && usersLength < 3" :slotId="slotIdWriters" layoutKey="-fb+5w+4e-db+86" :timeout="200" />


                        <div class="text-center">
                            <a href="javascript:$zopim.livechat.window.show();" class="small text-muted">Having issues? Let us know!</a>
                        </div>
                </nav>



                </div>
            </div>
        </div>
</template>


<script>
import Avatar from '../../Avatar'
import WriterProgressBar from './WriterProgressBar'
import { ContentLoader } from 'vue-content-loader'
import AdSenseInFeed from '../../AdSenseInFeed.vue'

export default {
    name: 'writers',

    components: {
        WriterProgressBar,
        ContentLoader,
        Avatar,
        AdSenseInFeed,
    },

    props: [
        'room', 'users', 'wordCounts', 'loading', 'unreadMessageCount', 'adText', 'randomAdNum', 'darkTheme',
    ],

    data() {
        return {
            hidePanel: false,
            showAd: true,
            slotIdWriters: false,
        }
    },

    watch: {
        darkTheme: function () {
            this.slotIdWriters = false
            setTimeout(() => {
                if (this.darkTheme == "true") {
                    this.slotIdWriters = "6090718988"
                }
                else {
                    this.slotIdWriters = "6735902034"
                }
            }, 1000)
        },
    },

    created() {
        if (this.darkTheme == "true") {
            this.slotIdWriters = "6090718988"
        }
        else {
            this.slotIdWriters = "6735902034"
        }
    },


    computed: {
        totalRoomWordsCount() {
            var sum = 0
            for (var key in this.wordCounts) {
                sum += this.wordCounts[key]
            }
            return Number(sum).toLocaleString() + ' word' + (sum != 1 ? 's' : '');
        },
        usersLength() {
            return _.size(this.users)
        },
        userList() {
            return _.values(this.users)
        }
    },

    methods: 
    {

        toggleMobileWriters() {
            this.$emit('toggleMobileWriters')
        },
        wordCount(user) {
            return this.wordCounts[user.id] ? this.wordCounts[user.id] : 0;
        },
        displayWordCount(user) {
            var count = this.wordCount(user);
            return Number(count).toLocaleString() + ' word' + (count != 1 ? 's' : '');
        },
        toggleSidebar() {
            this.hidePanel = (this.hidePanel ? false : true)
            this.$emit('toggleSidebar');
        },
        toggleChat() {
            this.$emit('toggleChat')
            this.showAd = true
        },
    },

}
</script>

<style scoped>
    .loader-content {
        height: 50px;
        width: 300px;
    }
    .hidden-sidebar .fe-chevron-right {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .hide-scrollbar {
        transition: none
    }
    .hidden-sidebar .hide-scrollbar {
        opacity: 0;
    }
    .open-sidebar-link {
        position: absolute;
        top: 33px;
        margin-left: 20px
    }
    .badge-chat-notify {
        position: absolute;
        left: 0px;
        top: 10px;
        padding: 2px 5px;
    }
</style>