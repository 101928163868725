<template>
    <div class="main main-visible" data-mobile-height="">
        
            <!-- Chat -->
            <div class="chat">

                <!-- Chat: body -->
                <div class="chat-body">

                    <!-- Chat: Header -->
                    <div class="chat-header border-bottom py-4 py-lg-6 px-lg-8">
                        <div class="container-xxl">

                            <div class="row align-items-center">

                                <!-- Close chat(mobile) -->
                                <div class="col-3 d-xl-none">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                        <router-link
                                            :to="{name: 'rooms'}"
                                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                                            >
                                            <a class="text-muted px-0" :href="href" @click="navigate">
                                                <i class="icon-md fe-chevron-left"></i>
                                            </a>
                                        </router-link>
                                        </li>
                                    </ul>
                                </div>

                                <!-- Chat photo -->
                                <div class="col-6 col-xl-6">
                                    <div class="media text-center text-xl-left">
                                        <div class="media-body align-self-center text-truncate">
                                            <h6 class="text-truncate mb-n1">Stats</h6>
                                            <small class="text-muted">How many words are you typing</small>
                                        </div>
                                    </div>
                                </div>

                            </div><!-- .row -->

                        </div>
                    </div>
                    <!-- Chat: Header -->

                    <!-- Chat: Content-->
                    <div class="chat-content px-lg-8">
                        <div class="container-xxl py-6 py-lg-10 animated fadeIn fast">
                            <!-- Card -->
                            <div class="card mb-4 mb-lg-8">

                                <div class="card-body">

                                    <div class="row align-items-center">
                                        <div class="col-12 col-md-6">
                             
                                            <h3 class="d-inline">Lifetime Total: {{ lifetimeWordCountString }}</h3> <span v-if="loadingTotalWordCount" class="ml-4 mb-2 spinner-border text-dark font-weight-light spinner-border-sm" role="status"></span> 
                                        </div>
                                        <div class="col-12 col-md-6 pr-0">
                                            <div class="row justify-content-end">
                                                <div class="col-md-6 pr-0">
                                                    <div class="row align-items-center">
                                                        <label class="col col-auto col-form-label small">Filter</label>
                                                        <div class="col-8">
                                                            <select name="" class="form-control" @change="changeFilter" v-model="filter">
                                                                <option value="7days">Last 7 Days</option>
                                                                <option value="14days">Last 14 Days</option>
                                                                <option value="30days">Last Month</option>
                                                                <option value="12months">Last 12 Months</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                            </div>
                            <!-- Card -->
                                <div class="container text-center">
                                    <ad-sense-in-feed :slotId="9228018210" ayoutKey="-fb+5w+4e-db+50" :timeout="200" />
                                </div>
                            <!-- Card -->
                            <div class="card mb-6 mb-lg-8">

                                <div class="card-body">
                                    <div class="text-center">
                                        <div v-if="!loadingFilter">{{ totalWordsInPeriod }}</div>
                                        <div v-if="loadingFilter" class="spinner-border text-dark spinner-border-sm" role="status"></div>
                                    </div>
                                    <div id="chart">
                                        <ApexChart type="line" height="350" :options="chartOptions" :series="chartOptions.series"></ApexChart>
                                    </div>
                                </div>

                            </div>
                            <!-- Card -->

                        </div>
                    </div>
                    <!-- Chat: Content -->

                </div>
                <!-- Chat: body -->

            </div>
            <!-- Chat -->

        </div>
</template>


<script>
import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import ApexChart from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import AdSenseInFeed from './AdSenseInFeed.vue'

export default {

    components: {
        ApexChart: () => import(/* webpackPrefetch: true */ 'vue-apexcharts'),
        AdSenseInFeed,
    },

    data() {
  
        return {
            stats: null,
            statsPeriodTotal: 0,
            filter: '7days',
            loadingFilter: false,
            loadingTotalWordCount: false,
            filterOptions: {
                '7days': '7 days',
                '14days': '14 days',
                '30days': '30 days',
                '12months': '12 months',
            },
            chartOptions: {
                chart: {
                    type: 'line'
                },
                series: [{
                    name: 'Word Count',
                    data: []
                }],
                xaxis: {
                    type: 'datetime',
                    categories: []
                },
                noData: {
                    text: 'Loading...'
                },
                stroke: {
                    width: 7,
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        gradientToColors: [ '#02c9ff', '#0973ff'],
                        shadeIntensity: 1,
                        type: 'horizontal',
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100, 100, 100]
                    },
                },
            },
        }
        
    },

    created() {
        this.fetchStats()
        this.fetchTotalWordCount()
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
        totalWordsInPeriod() {
            var filterName = this.filterOptions[this.filter]
            var count = this.statsPeriodTotal
            return Number(count).toLocaleString() + ' word' + (count != 1 ? 's' : '') 
                + ' in last ' + filterName
        },

        lifetimeWordCountString() {
            var count = this.lifetimeWordCount()
            return Number(count).toLocaleString() + ' word' + (count != 1 ? 's' : '') 
        }
    },

    methods: 
    {
        lifetimeWordCount() {
            return this.user.total_word_count
        },

        async fetchStats() 
        {
            this.loadingFilter = true
            await axios.get('/stats?filter=' + this.filter).then((res) => 
            {
                var stats = res.data.data;
                this.statsPeriodTotal = res.data.total;

                var newOptions = {
                    series: [{
                        name: 'Word Count',
                        data: _.values(stats)
                    }],
                    xaxis: {
                        type: 'datetime',
                        categories: _.keys(stats)
                    },
                }
                this.chartOptions = Object.assign({}, this.chartOptions, newOptions)
                this.stats = stats
            })
            .catch(() => 
            {

                Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                this.error = true;
            })
            .then(() => {
                this.loadingFilter = false
            })
        },

        fetchTotalWordCount() 
        {
            this.loadingTotalWordCount = true
            axios.get('/user/totalWordCount').then((res) => 
            {
                this.$store.commit('auth/SET_USER_WORD_COUNT', res.data.total);
                this.user.total_word_count = res.data.total
            })
            .catch(() => 
            {
                Vue.$toast.error('Ooops! Could not update lifetime word count. Please try again later')
                this.error = true;
            })
            .then(() => {
                this.loadingTotalWordCount = false
            })
        },

        changeFilter() {
            this.fetchStats()
        },
    }
}
</script>


<style scoped>
.chat-content {
    background: #f5f6fa;
}
</style>