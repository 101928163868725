<template>
    <div>
        <ValidationObserver ref="form" v-slot="{ passes }" enctype="multipart/form-data">
        <form class="mb-6" @submit.prevent="passes(submit)">
            <div class="form-group">
                <label class="small">Avatar</label>

                <div v-if="avatar && !image" class="avatar-photo">
                    <img :src="avatarImage" class="rounded-circle">
                </div>

                <Cropper
                    v-if="image"
                    classname="upload-cropper"
                    ref="cropper"
                    :minHeight="20"
                    :stencilProps="{
                            height: 100,
                            width: 100
                        }"
                    :src="image"
                    :stencilComponent="$options.components.CircleStencil"
                />
                <div class="upload-placeholder position-relative text-center bg-secondary rounded p-6">
                    <div class="avatar bg-primary text-white mb-5">
                        <i class="icon-md fe-image"></i>
                    </div>

                    <p class="small text-muted mb-0">You can upload jpg, gif or png files. <br> Max file size 3mb.</p>
                    <input id="upload-user-photo" class="d-none" type="file" ref="file" @change="uploadImage($event)" accept="image/*">
                    <label class="stretched-label mb-0" for="upload-user-photo"></label>


                </div>
            </div>
            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                <div class="form-group">
                    <label class="small" for="profile-name">Name</label>
                    <input class="form-control form-control-lg" 
                        :class="[{'is-invalid': errors.length}]" 
                        name="profile-name" id="profile-name" 
                        type="text" 
                        placeholder="Type your name"
                        v-model="form.name">
                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'name' + index">
                        {{ error }}
                    </div>
                </div>
            </ValidationProvider>

            <div class="form-group">
                <label class="small" for="profile-username">Username (cannot be changed)</label>
                <input class="form-control form-control-lg" 
                name="profile-username" 
                id="profile-username" 
                type="text" disabled readonly :value="user.username">
            </div>

            <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                <div class="form-group">
                    <label class="small" for="profile-email">Email</label>
                    <input class="form-control form-control-lg" 
                    :class="[{'is-invalid': errors.length}]" 
                    name="profile-email" id="profile-email" 
                    type="email" 
                    placeholder="you@yoursite.com" 
                    v-model="form.email">
                    <div class="invalid-feedback" v-for="(error, index) in errors" :key="'name' + index">
                        {{ error }}
                    </div>
                </div>
            </ValidationProvider>

            <div class="form-group">
                <label class="small" for="profile-about">Bio</label>
                <textarea class="form-control form-control-lg" 
                id="profile-about" 
                rows="3" placeholder="Express yourself" 
                data-autosize="true" 
                v-model="form.bio"></textarea>
            </div>

            <button class="btn btn-lg btn-primary btn-block" :class="[{'disabled': sending}]" type="submit" :disable="sending">
                Save Profile <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div>
            </button>
        </form>
        </ValidationObserver>
    </div>
</template>


<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isError422 } from "../../utils/response";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';
import axios from 'axios'
import { Cropper, CircleStencil } from 'vue-advanced-cropper'

extend('email', {
  ...email,
  message: 'Invalid email address'
});
extend('required', {
  ...required,
  message: 'Required'
});
extend('confirmed', confirmed);
extend('min', min);

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Cropper,
        CircleStencil,
    },
    data () {
        return {
            form: {
                name: null,
                email: null,
                bio: null,
            },
            image: null,
            avatar: null,
            sending: false,
            error: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/user', 
        }),
        avatarImage() {
            if (!this.avatar) {
                return null
            }
            return this.avatar.url + this.avatar.path
        }
    },
    created() {
        this.form = {
            name: this.user.name,
            email: this.user.email,
            bio: this.user.bio,
        }
        this.avatar = this.user.avatar
    },
    methods: 
    {
		uploadImage(event) {
			// Reference to the DOM input element
			var input = event.target;
			// Ensure that you have a file before attempting to read it
			if (input.files && input.files[0]) {
					// create a new FileReader to read this image and convert to base64 format
					var reader = new FileReader();
					// Define a callback function to run, when FileReader finishes its job
					reader.onload = (e) => {
							// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
							// Read image as base64 and set to imageData
                            this.image = e.target.result;
					}
					// Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);
			}
        },

        // Submit profile form
        async submit() {
            this.sending = true
            this.error = false

            let formData = new FormData()            
            var promise = new Promise((resolve) => {resolve()});
            if (this.image) 
            {
                const {canvas} = this.$refs.cropper.getResult()
                promise = new Promise((resolve) => {
                    canvas.toBlob((blob) => {
                        formData.append('avatar', blob)
                        resolve()
                    });
                });

            }

            promise.then(() =>
            {
                for ( var key in this.form ) {
                    formData.append(key, this.form[key]);
                }
                axios.get('/csrf-cookie').then(() => 
                {
                    axios.post('/user', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        this.$store.commit('auth/SET_USER', response.data);
                        Vue.$toast.success('Profile saved')
                        if (typeof response.data.avatar !== undefined) {
                            this.avatar = response.data.avatar
                            this.image = false
                        }
                    })
                    .catch((err) => 
                    {
                        if (isError422(err)) {
                            const errors = err.response.data.errors;
                            this.$refs.form.setErrors(errors);
                            return;
                        }
                        Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                        this.error = true;
                    })
                    .then(() => {
                        this.sending = false;
                    });
                });
            });

        },




    }
}
</script>

<style scoped>
.upload-cropper {
	border: solid 1px #EEE;
    border-bottom: 0;
	height: 300px;
	width: 100%;
    background: #333;
    margin-bottom: 10px;
    border-radius: 6px;
}
.avatar-photo {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    padding-bottom: 20px;
    height: 220px;
}
.avatar-photo img {
    width: 100%;
}
</style>