import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../components/SignIn.vue'
import SignUp from '../components/SignUp.vue'
import ForgotPassword from '../components/ForgotPassword.vue'
import ResetPassword from '../components/ResetPassword.vue'
import VerifyEmail from '../components/VerifyEmail.vue'
import Rooms from '../components/Rooms/Rooms.vue'
import RoomsView from '../components/Rooms/View.vue'
import MyAccount from '../components/MyAccount.vue'
import Stats from '../components/Stats.vue'
import Goals from '../components/Goals.vue'
import PageNotFound from '../components/404.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: SignIn,
    meta: { 
      layout: 'PublicLayout',
      title: 'Signin',
    },
  },
  {
    path: '/password/forgot',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: { 
      layout: 'PublicLayout',
      title: 'Forgot Password'
    },
  },
  {
    path: '/password/reset/:token',
    name: 'resetPassword',
    component: ResetPassword,
    meta: { 
      layout: 'PublicLayout',
      title: 'Reset Password'
    },
  },
  {
    path: '/verify/email/:hash/:id',
    name: 'verifyEmail',
    component: VerifyEmail,
    meta: { 
      layout: 'PublicLayout',
      title: 'Verify Email'
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    meta: { 
      layout: 'PublicLayout',
      title: 'Signup',
    },
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: Rooms,
    alias: '/',
    meta: { 
      layout: 'AppLayout',
      title: 'Sprint Rooms',
    },
  },
  {
    path: '/rooms/:uuid',
    name: 'roomsView',
    component: RoomsView,
    meta: { 
      layout: 'AppLayout',
      title: 'Sprint Room'
    },
  },
  {
    path: '/myaccount',
    name: 'myaccount',
    component: MyAccount,
    meta: { 
      layout: 'AppLayout',
      title: 'My Account'
    },
  },
  {
    path: '/stats',
    name: 'stats',
    component: Stats,
    meta: { 
      layout: 'AppLayout',
      title: 'Stats'
    },
  },
  {
    path: '/goals',
    name: 'goals',
    component: Goals,
    meta: { 
      layout: 'AppLayout',
      title: 'Goals'
    },
  },




  // 404 Not found, has to stay at bottom
  {
    path: '/*',
    name: '404',
    alias: '/404',
    component: PageNotFound,
    meta: { 
      layout: 'PublicLayout',
      title: 'Not Found',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const openRoutes = ['home', 'signin', 'signup', 'forgotPassword', 'resetPassword', 'verifyEmail'];
router.beforeEach((to, from, next) => 
{
    if (!openRoutes.includes(to.name) && !store.getters['auth/authenicated']) {
      const path = window.location.pathname;
      return next({
        name: 'signin',
        params: {
          'redirect': path,
        }
      })
    }


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title + ", ohwrite";

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));


  next()
});

export default router
