var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation navbar navbar-light justify-content-center py-xl-7"},[_c('router-link',{attrs:{"to":{name: 'rooms'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"d-none d-xl-block mb-6",attrs:{"href":href},on:{"click":navigate}},[_c('img',{attrs:{"src":"/assets/images/logo-pen.png","width":"60"}})])]}}])}),_c('ul',{staticClass:"nav navbar-nav flex-row flex-xl-column flex-grow-1 justify-content-between justify-content-xl-center py-3 py-lg-0",attrs:{"role":"tablist"}},[_vm._m(0),_c('li',{staticClass:"nav-item mt-xl-9"},[_c('router-link',{attrs:{"to":{name: 'rooms'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Rooms'),expression:"'Rooms'"}],staticClass:"nav-link position-relative p-0 py-xl-3",class:[{'active': isActive}],attrs:{"href":href,"title":"Rooms"},on:{"click":navigate}},[_c('i',{staticClass:"icon-lg fe-edit"}),(isActive)?_c('div',{staticClass:"badge badge-dot badge-primary badge-bottom-center"}):_vm._e()])]}}])})],1),_c('li',{staticClass:"nav-item mt-xl-9"},[_c('router-link',{attrs:{"to":{name: 'stats'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Stats'),expression:"'Stats'"}],staticClass:"nav-link position-relative p-0 py-xl-3",class:[{'active': isActive}],attrs:{"href":href,"title":"Stats"},on:{"click":navigate}},[_c('i',{staticClass:"icon-lg fe-bar-chart-2"}),(isActive)?_c('div',{staticClass:"badge badge-dot badge-primary badge-bottom-center"}):_vm._e()])]}}])})],1),_c('li',{staticClass:"nav-item mt-xl-9"},[_c('router-link',{attrs:{"to":{name: 'myaccount'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('My Account'),expression:"'My Account'"}],staticClass:"nav-link position-relative p-0 py-xl-3",class:[{'active': isActive}],attrs:{"href":href,"title":"My Account"},on:{"click":navigate}},[_c('i',{staticClass:"icon-lg fe-user"}),(isActive)?_c('div',{staticClass:"badge badge-dot badge-primary badge-bottom-center"}):_vm._e()])]}}])})],1),_c('li',{staticClass:"nav-item mt-xl-9 flex-xl-grow-1"},[_c('router-link',{attrs:{"to":{name: 'goals'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Goals'),expression:"'Goals'"}],staticClass:"nav-link position-relative p-0 py-xl-3",class:[{'active': isActive}],attrs:{"href":href,"title":"Goals"},on:{"click":navigate}},[_c('i',{staticClass:"icon-lg fe-award"}),(isActive)?_c('div',{staticClass:"badge badge-dot badge-primary badge-bottom-center"}):_vm._e()])]}}])})],1),_c('li',{staticClass:"nav-item mt-xl-9"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Logout'),expression:"'Logout'"}],staticClass:"nav-link position-relative p-0 py-xl-3",attrs:{"href":"#","title":"Logout"},on:{"click":function($event){$event.preventDefault();return _vm.signOut.apply(null, arguments)}}},[_c('i',{staticClass:"icon-lg fe-log-out"})])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item d-none d-xl-block invisible flex-xl-grow-1"},[_c('a',{staticClass:"nav-link position-relative p-0 py-xl-3",attrs:{"href":"#","title":""}},[_c('i',{staticClass:"icon-lg fe-x"})])])}]

export { render, staticRenderFns }