import axios from 'axios'
import moment from 'moment-timezone'

export default {
    namespaced: true,

    state: {
        user: null,
    },

    getters: {
        authenicated (state) {
            return (state.user !== null)
        },

        user(state) {
            return state.user
        },
    },

    mutations: {
        SET_USER (state, user) {
            state.user = user
        },
        SET_USER_WORD_COUNT (state, count) {
            state.user.total_word_count = count
        },
    },
    actions: {
        // Signin action
        async signIn({ dispatch }, credentials) {

            await axios.get('/csrf-cookie')
            await axios.post('/login', credentials).then(() => {
                localStorage.setItem('loggedIn', true);
            });

            return dispatch('attempt');
            
        },
        /// Attempt to get user details
        async attempt ({ commit }) 
        {
            if (!localStorage.getItem('loggedIn')) {
                return
            }

            try {
                let response = await axios.get('/user?tz=' + moment.tz.guess());
                commit('SET_USER', response.data);
                Echo.join('App.User.' + response.data.id);
            }
            catch (e) {
                commit('SET_USER', null);
                localStorage.removeItem('loggedIn');
            }
        },

        signOut({ commit, getters }) {
            Echo.leave('App.User.' + getters.user.id);
            return axios.post('/logout').then(() => {
                commit('SET_USER', null);
                localStorage.removeItem('loggedIn');
            });
        }
    },
    modules: {

    }
};
