<template>
    <div class="animated fadeIn fast">
        <!-- Heading -->
        <h1 class="font-bold text-center">Verifiy Email</h1>

        <div v-if="success">

            <p class="text-center">
                Your email has been verified. Thank you!
            </p>

            <div v-if="!authenicated">
                <p class="text-center" >
                    <router-link :to="{name: 'signin'}">Sign in</router-link>
                </p>
            </div>
            <div v-else>
                <p class="text-center" >
                    <router-link :to="{name: 'rooms'}">Continue</router-link>
                </p>
            </div>

        </div>
        <div v-else>

            <div v-if="error">
                <p class="alert alert-danger text-center">
                    Invalid verify link. Please try again later.
                </p>
                <p class="text-center">It's ok, you can still login. <router-link :to="{name: 'signin'}">Sign in</router-link>.</p>
            </div>
            <div v-else>
            <p class="text-center">
                <span class="spinner-border text-light spinner-border-sm mr-4" role="status"></span> Your email is being verified....
            </p>
            </div>
  
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    name: 'verifyEmail',

    computed: {
        ...mapGetters({
            authenicated: 'auth/authenicated', 
        }),
    },

    data() {
        return {
            error: true,
            success: false,
        }
    },

    created() {
        this.verifyEmail()
    },

    methods: {

        // /password/email
        async verifyEmail() {
            this.error = false;
            await axios.get('/csrf-cookie');
            await axios.post('/verify/email/' + this.$route.params.hash + '/' + this.$route.params.id).then((res) => 
            {
                this.success = true
            })
            .catch((err) => 
            {
                this.error = true;
            })

        },

    }
}
</script>
