<template>
	<div id="app">
		<component :is="addLayout">
			<router-view />
		</component>
	</div>
</template>

<script>
	const defaultLayout = "AppLayout";
		export default {
			name: "App",
			computed: {
				addLayout() {
					return this.$route.meta.layout || defaultLayout;
				}
			},

			mounted() {
				// Check theme
				var theme = localStorage.getItem('darkTheme');
				console.log(theme)
				if (theme == "true") {
					document.getElementById('theme-dark').media='all';
					document.getElementById('theme-light').media='none';
					document.querySelector('body').classList.add('dark');
				}
			},

			methods: 
			{

			}
		};
</script>