<template>
    <div class="animated fadeIn fast">

        <div v-if="signingIn" class="text-center animated fadeIn fast">

            <div class="spinner-border text-dark spinner-border-lg mb-2 mt-5" role="status"></div>
            <h1 class="font-bold text-center mb-8">Confirm your email</h1>

            <p>To complete your registration,<br> check your email for validation request.</p>

            <p><router-link :to="{name: 'signin'}">Sign in</router-link>.</p>
        </div>
        <div v-else>

            <h1 class="font-bold text-center">Sign Up</h1>
        
            <p class="text-center mb-6">Please sign up below.</p>

            <div class="alert alert-danger" role="alert" v-if="error">Something went wrong, please try again later</div>
    

            <!-- Form -->
            <ValidationObserver ref="form" v-slot="{ passes }" v-if="!signingIn">
            <form class="mb-6" @submit.prevent="passes(submit)">
                <!-- Name -->
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="name" class="sr-only">Full Name</label>
                        <input type="name" class="form-control form-control-lg" id="name" :class="[{'is-invalid': errors.length}]" placeholder="Enter your name" v-model.trim="form.name">
                        <div class="invalid-feedback" v-for="(error, index) in errors" :key="'email' + index">
                            {{ error }}
                        </div>
                    </div>
                </ValidationProvider>

                <!-- Email -->
                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="email" class="sr-only">Email Address</label>
                        <input type="email" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="email" placeholder="Enter your email" v-model="form.email">
                        <div class="invalid-feedback" v-for="(error, index) in errors" :key="'email' + index">
                            {{ error }}
                        </div>
                    </div>
                </ValidationProvider>

                <!-- Username -->
                <ValidationProvider name="username" :rules="{ required: true }" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="username" class="sr-only">Username</label>
                        <input type="username" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="username" placeholder="Enter your username" v-model="form.username">
                        <div class="invalid-feedback" v-for="(error, index) in errors" :key="'username' + index">
                            {{ error }}
                        </div>
                    </div>
                </ValidationProvider>

                <!-- Password -->
                <ValidationProvider name="password" vid="password" rules="required|min:6" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="password" class="sr-only">Password</label>
                        <input type="password" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="password" placeholder="Enter your password" v-model="form.password">
                        <div class="invalid-feedback" v-for="(error, index) in errors" :key="'password' + index">
                            {{ error }}
                        </div>
                    </div>
                </ValidationProvider>

                <!-- Confirm Password -->
                <ValidationProvider name="passwordConfirmation" vid="passwordConfirmation" rules="required|confirmed:password" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="password_confirmation" class="sr-only">Confirm Password</label>
                        <input type="password" class="form-control form-control-lg" :class="[{'is-invalid': errors.length}]" id="password_confirmation" placeholder="Confirm your password" v-model="form.password_confirmation">
                        <div class="invalid-feedback" v-for="(error, index) in errors" :key="'password_confirmation' + index">
                            {{ error }}
                        </div>
                    </div>
                </ValidationProvider>

                <!-- Submit -->
                <button class="btn btn-lg btn-block btn-primary" :class="[{'disabled': sending}]" type="submit" :disable="sending">Sign up <div v-if="sending" class="spinner-border text-light spinner-border-sm ml-2" role="status"></div></button>
            </form>
            </ValidationObserver>

            <!-- Text -->
            <p class="text-center">
                Already have an account? <router-link :to="{name: 'signin'}">Sign in</router-link>.
            </p>
        </div>
    </div>
</template>


<script>

import { mapActions } from 'vuex'
import { isError422 } from "../utils/response";
import { setInteractionMode, ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, alpha_dash, alpha_num, confirmed, min } from 'vee-validate/dist/rules';
import axios from 'axios'

setInteractionMode('passive');
extend('email', {
  ...email,
  message: 'Invalid email address'
});
extend('required', {
  ...required,
  message: 'Required'
});
extend('alpha_dash', alpha_dash);
extend('alpha_num', alpha_num);
extend('confirmed', confirmed);
extend('min', min);


export default {
    name: 'signin',

    components: {
        ValidationProvider,
        ValidationObserver
    },

    data() {
        return {
            form: {
                name: '',
                email: '',
                username: '',
                password: '',
                password_confirmation: '',
            },
            sending: false,
            error: false,
            signingIn: false,
        }
    },


    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
        }),

        // Submit registration form
        async submit() {
            this.sending = true;
            this.error = false;

            await axios.post('/register', this.form).then(() => 
            {
                // Now attempt to sign user in with same information
                //this.signInAction();
                this.signingIn = true
            })
            .catch((err) => 
            {
                if (isError422(err)) {
                    const errors = err.response.data.errors;
                    this.$refs.form.setErrors(errors);
                    return;
                }
                this.error = true;
            })
            .then(() => {
                this.sending = false;
            });

        },

        // Sign in action to sign user in after registering
        async signInAction() 
        {
            this.signIn(this.form).then(() => 
            {
                if (this.$route.params.redirect) {
                    this.$router.replace(this.$route.params.redirect);
                    return
                }
                this.$router.replace({
                    name: 'rooms',
                });
            })
            .catch(() => {
                this.error = true;
            })
        },
    }
}
</script>
