<template>
    <div :class="classNames">
        <ins
        class="adsbygoogle"
        style="display: block"
        :data-ad-client="googleAdId"
        :data-ad-slot="slotId"
        data-ad-format="auto"
        data-full-width-responsive="true"
        ></ins>
    </div>
</template>


<script>
export default {
  name: "AdSense",
  props: ['timeout', 'classNames', 'slotId'],
  data() {
    return {
      googleInit: null,
      googleAdId: "ca-pub-8241810292521301"
    };
  },
  mounted() {
  	let timeout = 200;
    if(this.timeout) timeout = this.timeout;
    this.googleInit = setTimeout(() => {
      if (typeof window !== "undefined")
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, timeout);
  },
  destroyed() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }
};
</script>
