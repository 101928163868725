<template>
    <div :class="classNames">
        <img v-if="user.avatar" class="avatar-img" :src="avatarUrl" :alt="user.name" v-tooltip="tooltopContent()">
        <span v-else class="avatars__initials" v-tooltip="tooltopContent()">{{ user.initials }}</span>
    </div>
</template>


<script>
export default {
    name: 'avatar',
    props: ['user', 'classNames'],

    computed: {
        avatarUrl() {
            return this.user.avatar.url + "100x100/" + this.user.avatar.path;
        },
    },
    methods: {
        tooltopContent() {
            return this.user.name + "\r\n" +
            "Lifetime words: " + Number(this.user.total_word_count).toLocaleString()
        }
    }
}
</script>


<style>
.avatars__initials {
    background-color: #596376 ;
    border-radius: 50% ;
    color: #FFFFFF ;
    display: block ;
    font-family: sans-serif ;
    font-size: 12px ;
    font-weight: 100 ;
    height: 50px ;
    line-height: 50px ;
    text-align: center ;
    width: 50px ;
}
.thumb .avatars__initials {
    height: 30px;
    width: 30px;
    line-height: 30px;
}
</style>