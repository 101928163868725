<template>
  <div class="wrapper-main">
      <!-- Sidebar -->
      <div class="sidebar">
          <div class="tab-content h-100" role="tablist">
              <div class="tab-pane fade h-100 show active" id="tab-content-dialogs" role="tabpanel">
                  <div class="d-flex flex-column h-100">

                      <div class="hide-scrollbar">
                          <div class="container-fluid py-6">
                              <!-- Title -->
                              <h2 class="font-bold mb-6">Sprint Rooms</h2>
                              <!-- Title -->

                              <!-- Search -->
                              <div class="mb-4">
                                  <div class="input-group">
                                      <input v-model="search" type="text" class="form-control form-control-lg" placeholder="Filter your rooms..." aria-label="Filter your rooms...">
                                      <div class="input-group-append">
                                          <button class="btn btn-lg btn-ico btn-secondary btn-minimal" type="submit">
                                              <i class="fe-search"></i>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <!-- Search -->

                            <div class="btn-toolbar btn-block mb-6">

                                <button data-toggle="modal" data-target="#create-room" type="button" class="btn btn-primary" style="flex-grow: 2">Create Room</button>
                                <router-link
                                    :to="{name: 'roomsView', params: {uuid: 'global-room', name: 'Global'}}"
                                    v-slot="{ href, route, navigate }"
                                >
                                <button @click="navigate" type="button" class="btn btn-dark ml-2" style="flex-grow: 1">Join Global Room</button>
                                </router-link>
                            </div>

                            <!-- Chats -->
                            <nav class="nav d-block list-discussions-js mb-n6">

                                <div v-if="loading.rooms">
                                    <div v-for="n in 2">
                                        <a class="text-reset nav-link p-0 mb-6">
                                            <div class="card card-active-listener">
                                                <div class="card-body">
                                                    <div class="loader-content">
                                                        <ContentLoader
                                                            width=200
                                                            height=50
                                                            primaryColor="#f3f3f3"
                                                            secondaryColor="#ecebeb"
                                                            >
                                                            <circle cx="21" cy="38" r="12" /> 
                                                            <rect x="9" y="9" rx="2" ry="2" width="139" height="8" /> 
                                                            <circle cx="44" cy="38" r="12" />
                                                        </ContentLoader>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                                <div v-else>
                                    <ad-sense-in-feed v-if="slotIdRoomsList" :slotId="slotIdRoomsList" layoutKey="-fb+5w+4e-db+86" :timeout="200" classNames="mb-6" />
     
                                    <div v-if="!filteredRooms.length" class="p-5 text-center small">
                                        You haven't created or joined any rooms yet. You must join a room to start sprinting with friends!
                                    </div>
                                    <div v-else>
                                        <div v-for="(room, index) in filteredRooms" :key="'room' + index">
                                            
                                            <!-- Room User -->
                                            <router-link
                                                :to="{name: 'roomsView', params: room}"
                                                v-slot="{ href, route, navigate }"
                                            >
                                            <a class="text-reset nav-link p-0 mb-6 animated fadeIn faster">
                                                <div class="card card-active-listener">
                                                    <div class="card-body">

                                                        <div class="media">
                                                            
                                                            <div class="media-body overflow-hidden">

                                                                <div class="row">
                                                                    <div class="col-7" :class="{ 'd-flex align-items-center': !membersExist(room) }">
                                                                        <h6 class="text-truncate mr-auto" @click="navigate">{{ room.name }}</h6>
                                                                        <div v-if="membersExist(room)" class="mt-2">
                                                                            <ul class="avatars thumb">
                                                                                <li v-if="usersRoomOverflow(room)" class="avatars__item">
                                                                                    <span class="avatars__others">+{{ usersRoomOverflow(room) }}</span>
                                                                                </li>
                                                                                <li v-for="(roomUser, index) in usersInRoomList(room)" 
                                                                                    :key="'roomUser' + index" class="avatars__item"
                                                                                    
                                                                                >
                                                                                    <Avatar :user="roomUser"></Avatar>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex col align-items-center justify-content-end">
                                                                        <a href="" class="text-reset" style="font-size: 12px" @click.prevent="removeRoom(room)">Leave</a>
                                                                        <button class="btn btn-primary btn-sm ml-5" @click="navigate">Join</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </a>
                                            </router-link>
                                            <!-- /Room User -->

                                        </div>

                                    </div>
                                    

                                </div>                                 
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                    
      <!-- Sidebar -->

      <!-- Main Content -->
      <div class="main" data-mobile-height="">

        <!-- Default Page -->
        <div class="chat h-100 flex-column justify-content-center text-center">
            <div class="container-xxl animated fadeIn h-100 hide-scrollbar" style="overflow:scroll;padding-top: 100px">

                <div class="row justify-content-center align-items-center mb-4 animated fadeInDown">

                    <div class="col-flex mr-6">
                        <Avatar :user="user" classNames="avatar avatar-xlg"></Avatar> 
                    </div>
                    <div class="col-flex text-left align-items-center">
                        <strong class="greeting-name">Hey, {{ user.name }}!</strong><br>
                        <router-link
                            :to="{name: 'myaccount'}"
                                    v-slot="{ href, route, navigate }"
                        >
                            <button @click="navigate" v-if="!user.avatar" type="button" class="btn btn-sm btn-secondary mt-3">Upload My Avatar</button>
                        </router-link>
                        <router-link
                            :to="{name: 'stats'}"
                                    v-slot="{ href, route, navigate }"
                        >
                        <button @click="navigate" v-if="user.avatar" type="button" class="btn btn-sm btn-secondary mt-3">View My Stats</button>
                        </router-link>
                    </div>
                </div>

                <div class="mb-10">
                    <div class="custom-control custom-switch d-inline">
                        <input type="checkbox" ref="darkTheme" class="custom-control-input" id="custom-switch-1" value="1" :checked="darkTheme == 'true'" @click="changeTheme()">
                        <label class="custom-control-label" for="custom-switch-1"></label>
                    </div> Dark Mode
                </div>


                <h6 class="mb-6">Welcome to OhWrite.</h6>
                <ul class="p-0" style="list-style: none">
                    <li><i class="icon fe-check-circle text-primary"></i> Write with your friends</li>
                    <li><i class="icon fe-check-circle text-primary"></i> Auto record your word counts</li>
                    <li><i class="icon fe-check-circle text-primary"></i> Write in dark mode</li>
                </ul>

                <p class="pt-6 d-none">
                    <button type="button" data-toggle="modal" data-target="#create-room" class="btn btn-primary">Create Room</button> 
                </p>

<div class="row"><div class="col-sm-12 col-md-12 col-lg-8 m-auto"><div class="text-muted small mt-8">Try another product by OhWrite</div><a href="https://bookedge.co/?ref=ohwrite-h"><img src="/assets/images/bookedge-home-ad.png" class="w-100 mr-n8"></a></div></div>
                

                <!--
                <h6 class="mb-3">Roadmap</h6>
                <ul class="p-0" style="list-style: none">
                    <li><i class="icon fe-check-circle text-primary"></i> Ability to add friends and view their profiles</li>
                    <li><i class="icon fe-check-circle text-primary"></i> Create goals, and auto record your word counts towards a goal.</li>
                    <li><i class="icon fe-check-circle text-primary"></i> Save your work to Google Drive or encrypted on our servers</li>
                </ul>

                <p>If you have any feedback or find any issues with ohwrite, please <a href="mailto:feedback@ohwrite.co">contact us</a>.</p>


                -->

            </div>
        </div>
        <!-- Default Page -->

      </div>
      <!-- Main Content -->

       <CreateModal />


  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ContentLoader } from 'vue-content-loader'
import axios from 'axios'
import { VTooltip } from 'v-tooltip'
import _ from 'lodash'
import Avatar from '@/components/Avatar'
import AdSense from '../AdSense.vue'
import AdSenseInFeed from '../AdSenseInFeed.vue'

const CreateModal = () => ({
  component: import('@/components/Rooms/CreateModal'),
})

Vue.directive('tooltip', VTooltip)

export default {

    name: 'rooms',

    data() {
        return {
            loading: {
                rooms: true,
            },
            search: '',
            rooms: [],
            darkTheme: false,
            slotIdRoomsList: false,
        };
    },
    components: {
        ContentLoader,
        Avatar,
        CreateModal,
        AdSense,
        AdSenseInFeed,
    },


    computed: {
        ...mapGetters({
            user: 'auth/user',
            
        }),
        filteredRooms() {
            return this.rooms.filter(item => {
                return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            })
        },
    },

    watch: {
        darkTheme: function () {
            this.slotIdRoomsList = false
            setTimeout(() => {
                if (this.darkTheme == "true") {
                    this.slotIdRoomsList = "3141122018"
                }
                else {
                    this.slotIdRoomsList = "1084614940"
                }
            }, 1000)
        },
    },

    methods: 
    {
        changeTheme(event) {
            this.darkTheme = this.darkTheme == "true" ? "false" : "true"
            if (this.darkTheme == "true") {
                document.getElementById('theme-dark').media='all';
                document.getElementById('theme-light').media='none';
                document.querySelector('body').classList.add('dark');
            }
            else {
                document.getElementById('theme-dark').media='none';
                document.getElementById('theme-light').media='all';
                document.querySelector('body').classList.remove('dark');
            }
            localStorage.setItem('darkTheme', this.darkTheme)
        },


        async fetchRooms() {
            await axios.get('/rooms').then((res) => 
            {
                this.rooms = res.data.data;
                this.loading.rooms = false;
                $('[data-toggle="tooltip"]').tooltip()
            })
            .catch((err) => 
            {
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                this.error = true;
            });
        },

        async removeRoom(room) {
            if (!confirm("Are you sure you want to leave this room?")) {
                return
            }

            var index = _.findKey(this.rooms, { 'id': room.id });
            this.$delete(this.rooms, index)

            await axios.delete('/rooms/' + room.uuid + '/users').catch((err) => 
            {
                Vue.$toast.error('Ooops! Something wrong happened. Please try again later')
                this.error = true;
            });
        },

        membersExist(room) {
            return _.size(room.users) > 0
        },

        usersInRoomList(room) {
            return _.slice(room.users, 0, 4)
        },
        usersRoomOverflow(room) {
            if (_.size(room.users) < 4) {
                return false
            }
            return _.size(room.users) - 4;
        }
    },

    created() {
        var darkTheme = localStorage.getItem('darkTheme');
        this.darkTheme = darkTheme
        
        
        this.fetchRooms();
        $('[data-toggle="tooltip"]').tooltip()
        
    }
}
</script>


<style>
.loader-content {
    width: 200px;
    height: 50px;
}
ul.avatars {
    display: flex ; /* Causes LI items to display in row. */
    list-style-type: none ;
    margin: auto ; /* Centers vertically / horizontally in flex container. */
    padding: 0px 7px 0px 0px ;
    justify-content: flex-end;

    /*
        By using the row-reverse layout, the visual ordering will be opposite of
        the DOM ordering. This will allows us to stack the items in the opposite
        direction of the natural stacking order without having to mess with the
        zIndex value. The MAJOR DOWNSIDE is that the HTML itself now reads
        backwards, which super janky.
    */
    flex-direction: row-reverse ;
}

li.avatars__item {
    height: 38px ;
    margin: 0px 0px 0px 0px ;
    padding: 0px 0px 0px 0px ;
    position: relative ;
    width: 26px ; /* Forces flex items to be smaller than their contents. */
}

/*
    These items are all 49px wide while the flex-item (in which they live) is
    only 42px wide. As such, there will be several pixels of overflow content,
    which will be stacked using the natural DOM-based stacking order.
*/
li img.avatar-img,
span.avatars__others {
    background-color: #596376 ;
    border-radius: 100px 100px 100px 100px ;
    color: #FFFFFF ;
    display: block ;
    font-family: sans-serif ;
    font-size: 12px ;
    font-weight: 100 ;
    height: 30px ;
    line-height: 30px ;
    text-align: center ;
    width: 30px ;
}

span.avatars__others {
    background-color: #1E8FE1 ;
}
.room-options {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  white-space: pre-wrap;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.avatar-xlg, .avatar-xlg>img {
    height: 5rem;
    min-height: 5rem;
    width: 5rem;
    min-width: 5rem;
}
.greeting-name {
    color: var(--basic-inverse)
}
</style>