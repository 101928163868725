import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import Echo from "laravel-echo"
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import 'animate.css';
import VueGtag from "vue-gtag";
import VTooltip from 'v-tooltip'
import EventBus from './utils/eventBus'

//import Adsense from 'vue-google-adsense/dist/Adsense.min.js'
//import InFeedAdsense from 'vue-google-adsense/dist/InFeedAdsense.min.js'

import AppLayout from './layouts/AppLayout';
import PublicLayout from './layouts/PublicLayout';

window.Pusher = require('pusher-js');

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL + "/api"
Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "UA-164847804-1" }
}, router);

Vue.use(VTooltip)
Vue.use(VueToast);

//Vue.use(require('vue-script2'))

//Vue.use(Adsense)
//Vue.use(InArticleAdsense)
//Vue.use(InFeedAdsense)

Vue.component("AppLayout", AppLayout);
Vue.component("PublicLayout", PublicLayout);

var echoConfig = {
    broadcaster: 'pusher',
    key: 'b21fed85-4b34-4505-8095-760d2951e53d',
    wsHost: process.env.VUE_APP_WEBSOCKET_URL,
    wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
    disableStats: process.env.VUE_APP_WEBSOCKET_DISABLE_STATS,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel, options) => {
      return {
          authorize: (socketId, callback) => {
              axios.post('/broadcasting/auth', {
                  socket_id: socketId,
                  channel_name: channel.name
              })
              .then(response => {
                  callback(false, response.data);
              })
              .catch(error => {
                  callback(true, error);
              });
          }
      };
  },
}
if (process.env.VUE_APP_WEBSOCKET_ENCRYPTED != "false") {
  echoConfig.encrypted = true
}

// If logged in, then we enable echo
window.Echo = new Echo(echoConfig);
applyEchoBinders();

function applyEchoBinders() {
  window.Echo.connector.pusher.connection.bind('state_change', (state) => {
    console.log(state);
    EventBus.$emit('SOCK_STATE_CHANGE', state) 
    if (state.previous == "connected" && state.current == "failed") {
      window.Echo = new Echo(echoConfig);
      applyEchoBinders();
    }
  })

}


// If no xsrf token exists, let's get one
axios.get('/csrf-cookie');

store.dispatch('auth/attempt').then(() => {
  var vm = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

  global.vm = vm; 

});
