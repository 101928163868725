<template>

        <div class="main main-visible" data-mobile-height="">

            <!-- Chat -->
            <div class="chat">

                <!-- Chat: body -->
                <div class="chat-body">

                    <!-- Chat: Header -->
                    <div class="chat-header border-bottom py-4 py-lg-6 px-lg-8">
                        <div class="container-xxl">

                            <div class="row align-items-center">

                                <!-- Close chat(mobile) -->
                                <div class="col-3 d-xl-none">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                        <router-link
                                            :to="{name: 'rooms'}"
                                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                                            >
                                            <a class="text-muted px-0" :href="href" @click="navigate">
                                                <i class="icon-md fe-chevron-left"></i>
                                            </a>
                                        </router-link>
                                        </li>
                                    </ul>
                                </div>

                                <!-- Chat photo -->
                                <div class="col-6 col-xl-6">
                                    <div class="media text-center text-xl-left">
                                        <div class="media-body align-self-center text-truncate">
                                            <h6 class="text-truncate mb-n1">My Account</h6>
                                            <small class="text-muted">Update your account details</small>
                                        </div>
                                    </div>
                                </div>

                            </div><!-- .row -->

                        </div>
                    </div>
                    <!-- Chat: Header -->

                    <!-- Chat: Content-->
                    <div class="chat-content px-lg-8">
                        <div class="container-xxl py-6 py-lg-10 animated fadeIn fast">

                            <!-- Accordion -->
                            <div class="accordion modified-accordion mb-n6 mb-lg-8" id="profile-settings">
                                <div class="card-columns">

                                    <!-- Card -->
                                    <div class="card mb-6 mb-lg-8">
                                        <div class="card-header position-relative">
                                            <a href="#" class="text-reset d-block stretched-link">
                                                <div class="row no-gutters align-items-center">
                                                    <!-- Title -->
                                                    <div class="col">
                                                        <h5>Account</h5>
                                                        <p>Update your profile details.</p>
                                                    </div>

                                                    <!-- Icon -->
                                                    <div class="col-auto">
                                                        <i class="text-muted icon-md fe-user"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div id="profile-settings-account">
                                            <div class="card-body">
                                                <ProfileForm />
                                            </div>
                                        </div>

                                    </div>
                                    <!-- Card -->



                                    <!-- Card -->
                                    <div class="card mb-6 mb-lg-8">
                                        <div class="card-header position-relative">
                                            <a href="#" class="text-reset d-block stretched-link">
                                                <div class="row no-gutters align-items-center">
                                                    <!-- Title -->
                                                    <div class="col">
                                                        <h5>Security</h5>
                                                        <p>Update your password.</p>
                                                    </div>

                                                    <!-- Icon -->
                                                    <div class="col-auto">
                                                        <i class="text-muted icon-md fe-shield"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div id="profile-settings-security">
                                            <div class="card-body">
                                                <PasswordForm />
                                            </div>
                                        </div>

                                    </div>
                                    <!-- Card -->

                                    <!-- Card -->
                                    <div class="card mb-6 mb-lg-8">
                                        <div class="card-header position-relative">
                                            <a href="#" class="text-reset d-block stretched-link">
                                                <div class="row no-gutters align-items-center">
                                                    <!-- Title -->
                                                    <div class="col">
                                                        <h5>Social</h5>
                                                        <p>Update your profile details.</p>
                                                    </div>

                                                    <!-- Icon -->
                                                    <div class="col-auto">
                                                        <i class="text-muted icon-md fe-share-2"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div id="profile-settings-social">
                                            <div class="card-body">
                                                <SocialsForm />
                                            </div>
                                        </div><!-- .collapse -->

                                    </div>
                                    <!-- Card -->



                                </div>
                            </div>
                            <!-- Accordion -->

                        </div>
                    </div>
                    <!-- Chat: Content -->

                </div>
                <!-- Chat: body -->

            </div>
            <!-- Chat -->

        </div>
        <!-- Main Content -->
 

</template>

<script>
import ProfileForm from "./MyAccount/ProfileForm"
import PasswordForm from './MyAccount/PasswordForm'
import SocialsForm from './MyAccount/SocialsForm'


export default {
    components: {
        ProfileForm,
        PasswordForm,
        SocialsForm,
    },
    data () {
        return {

        };
    },

}
</script>

<style scoped>
.chat-content {
    background: #f5f6fa;
}
</style>